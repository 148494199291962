import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './componenets/header'
import Searchbar from './componenets/search-bar'
import Grouplist from './componenets/group-list'

const Group = () => {

  return (
   
    <Box sx={{backgroundColor:'white'}}>
     <Container >
     <Box sx={{padding:5}}><Header/></Box>
        <Box><Searchbar/></Box>
         <Box>
         <Grouplist/>
         </Box>
     </Container>
    </Box>
  )
}

export default Group