import React from 'react'
import { Avatar, Box, Button, CircularProgress, colors, Container, Divider, Typography } from '@mui/material'
import  { useEffect, useState } from 'react'
import girl from '../../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url } from '../../config/config';
import TimeAgo from '../recordings/timeStmaps';
import { Handshake } from '@mui/icons-material';

const Seeall = () => {
    const [songcollaboration,setSongCollaboration] = useState([])
    const [pagination,setPagination] = useState(0)
    const [groups,setgroups]=useState([])
    const userTokanValue = localStorage.getItem('token');
    const navigate=useNavigate()
    const [songs,setSongs] = useState([])
    const [loading,setloading]=useState(true)
const [user,setUser] = useState([])
    const params = useParams();
    console.log(params,'paramsValuesss')

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.scrollY;
          const windowHeight = window.innerHeight;
          const documentHeight = document.documentElement.scrollHeight;
          console.log(scrollTop + windowHeight)
          console.log(documentHeight)
          if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
            setPagination(prevPagination => prevPagination + 1)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    useEffect(() => {
        const searchResult = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = userTokanValue !== null ? await axios.get(`${url}/api/GlobalFilter/getGlobalFilter/${params.textValue}?FilterIndex=${Number(params.value)}&page=${pagination}`,config) : await axios.get(`${url}/api/GlobalFilter/getGlobalOtherFilter/${params.textValue}?FilterIndex=${Number(params.value)}&page=${pagination}`)
                console.log(res.data.data,'SearchdataValuesssss')
                if(pagination===0){
                setSongCollaboration(res.data.data)              
                }
                else{
                    setSongCollaboration((prevValues) => [...prevValues, ...res.data.data])
                    
                }

            }catch (error) {
                console.log(error,'errorsearchResult')
            }
            finally {
                
                setloading(false);
              }
        }
        searchResult()  
    },[params.value,pagination])
    // songcollaboration && songcollaboration.map((val) => (
    //     console.log(val.users.length)
    // ))
    console.log(user)
    const handleNavigation = (val) => {
        navigate(`/searchPagination/${val}/${params.value}`,);
      };

      const handleBack = () => {
        navigate(`/search/${params.textValue}`)
      }
    console.log(songcollaboration,'songCollabDetailssssssssssss')
  return (
    <Container>
        

        <Box pt={4}>

        <Box sx={{display:'flex',gap:.2}}>
            <Box onClick={handleBack} sx={{fontSize:30,borderBottom:'1px solid black',cursor:'pointer',"&:hover " :{
                color:'lightgreen',borderBottom : '1px soild lightgreen'
            }}}>All results </Box>
            <Box><Typography fontSize={30}>{`> Showing results for "${params.textValue}"`}</Typography></Box>
        </Box>
        <Box sx={{display:'flex',justifyContent:'center'}}>
             {
              loading?<Box sx={{marginTop:20}}>
                <CircularProgress />
              
                
            </Box>:null
             }
          </Box>
        {
            params.value === '1' ?   <Box>
            {
                       songcollaboration && songcollaboration.map((data,index) => (
                       <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2,cursor:'pointer'}}>
              
                        <><Box key={index} sx={{ display: "flex", gap: 1 }}>
                               <Button  onClick={()=>{
          navigate(`/record/${data._id}`)
        }}><Avatar
                                   alt="Remy Sharp"
                                   src={data.CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' /></Button>
                               <Box sx={{marginTop:'5px'}}>
                                   <Box sx={{ pt: 0 }}><Typography  fontSize={'1em'} fontWeight={700}>{data.karaokes[0].Name}</Typography></Box>
                                   <Box><Typography fontSize={9} style={{width:100,color:'#aaa',fontSize:'1em',fontWeight:400}}>{data.users[0].Email}</Typography></Box>
                                   <Box sx={{ display: "flex", alignItems: "center",gap:.5}}>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><PlayArrowIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfPlay}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><FavoriteIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfLikes}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><ChatBubbleIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfComments}</Box>
                                       </Box>

                                   </Box>
                               </Box>
                           </Box><Box>
                            {
                                data.users.length > 1 ?  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                       <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                       <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                           <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                           <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                       </Box>

                                   </Box> <Box><Typography>+</Typography></Box> <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                           <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                           <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                               <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                               <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                           </Box>

                                       </Box></Box> : <Box onClick={() => {navigate(`/otherProfile/${data.users[0]?._id}`)}} sx={{display:'flex',gap:1}}>
                                    <Box sx={{fontSize:'11px',color:'#636363',marginTop:'10px'}}>Recorded by</Box>
                                    <Box>
                                        <Avatar src={data.users[0].ProfilePhoto}/>
                                    </Box>
                                    <Box sx={{color:'#636363',fontSize:'11px',marginTop:'10px'}}>
                                        {data.users[0].Name !== '' ? data.users[0].Name : data.users[0].Email}
                                    </Box>
                                </Box> 
                            }     
                               </Box><Box><Typography fontSize={13}>Recorded  <TimeAgo timestamp={data.createdAt}/></Typography></Box></>
            </Box> 
          
        ))
                }
        </Box> : params.value === '0' ?   <Box pt={2}>
             <Box  onClick={() => {handleNavigation(0)}} sx={{display:"flex",flexDirection:"column",padding:'15px',gap:2}}>
                {
                    songcollaboration && songcollaboration.map((val,ind) => (
                        
                        <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                        <Box sx={{display:'flex',gap:2}}>
                            <Box>
                            <Avatar
                                   alt="Remy Sharp"
                                   src={val.CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' />
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column',gap:.5,width:'400px'}}>
                                 <Box sx={{color:'#000',fontSize:'12px',fontWeight:700}}>{val.Name}</Box>
                                 <Box sx={{color:'#aaa',fontSize:'12px'}}>Hi-Fi</Box>
                                 <Box sx={{display:'flex'}}>
                                    <Box><AccountCircleIcon style={{color:'#aaa'}}/></Box>
                                    <Box sx={{color:'#aaa',fontSize:'12px'}}>{val.users[0]?.Email}</Box>
                                 </Box>
                            </Box>
                        </Box>
                        <Box sx={{marginTop:'10px',cursor:'pointer'}}>
                            <button onClick={() => {navigate(`/allsong/${val._id}`)}} style={{background:'none',border:'1px solid black',padding:'5px 10px',borderRadius:'5px',cursor:'pointer'}}>Lyrics</button>
                        </Box>
                        </Box>
                    ))
                }
             </Box>
        </Box> : params.value === '2' &&    <Box pt={2}>

<Box  onClick={() => {handleNavigation(2)}} sx={{gap:6}}>
{
    songcollaboration.length && songcollaboration.map((data,index)=>(
    <Box onClick={() => {navigate(`/otherProfile/${data._id}`)}}  key={index} sx={{display:"flex",flexDirection:"column",alignItems:"start",padding:'15px',gap:2,cursor:'pointer',borderBottom:'1px solid black',textAlign:'end'}}>
        <Box ><Avatar sx={{height:'100px',width:'100px'}} src={data.ProfilePhoto} /></Box>
        
        <Box sx={{textAlign:"left"}}><Typography fontSize={13} fontWeight={700}>{data.Name}</Typography></Box>
        
    </Box>

    ))
}
</Box>
</Box>
        }
    </Box>
    <Box sx={{display:"flex",gap:4,pt:2}}>
            {groups&&groups.map((group, index) => (
                <Box key={index} sx={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"left"}}>
                    <Box>
                        <Avatar
                            alt={group.ChannelName}
                            src={group.CoverImage}
                            sx={{ width: 170, height: 170 }}
                            variant='square'
                            onClick={() =>
                                navigate(`/othergroupinfo/${group._id}`, {
                                  state: group,
                                })
                              }
                        />
                    </Box>
                    <Box>
                        <Typography fontSize={13} fontWeight={700}>{group.ChannelName}</Typography>
                    </Box>
                    <Box sx={{display:"flex"}}>
                        {/* <Box><AccessTimeIcon sx={{fontSize:20}}/></Box> */}
                        <Box>{group.MemberUserIDsCount} members</Box>
                    </Box>
                </Box>
            ))}
        </Box>
    </Container>
  )
}

export default Seeall