import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../assest/images/Bright Color-01.png';

const Spark = () => {
  const navigate = useNavigate();

  const handlePrivacy = () => {
    navigate("/privacy-policy");
  };
  const handleTerms = () => {
    navigate("/terms-of-service");
  };
  const handleCopyright = () => {
    navigate("/copyright");
  };
  const handleGuidelines = () => {
    navigate("/guidelines");
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E5E5',
        height:"50%",
        display: 'flex',
        justifyContent: "space-around",
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {/* Left side: Copyright Text */}
      <Box sx={{ flex: 1, textAlign: 'left' }}>
        <Typography>© 2024 SparkSinger, Inc. All Rights Reserved.</Typography>
      </Box>

      {/* Center: Logo */}
      <Box
        sx={{
          width: { xs: '30%', sm: '15%' }, // Adjust for responsiveness
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <img
          src={image}
          alt="Logo"
          style={{
            maxWidth: '80%',
            height: 'auto',
            borderRadius: '8px',
          }}
        />
      </Box>

      {/* Right side: Links */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', sm: 'flex-end' }, // Center on small screens, right-align on larger screens
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Box
          onClick={handlePrivacy}
          sx={{
            fontSize: 16,
            borderBottom: '1px solid black',
            cursor: 'pointer',
            '&:hover': {
              color: '#1B1BDB',
              borderBottom: '1px solid #FF3FFF',
            },
          }}
        >
          Privacy Policy
        </Box>
        <Box
          onClick={handleTerms}
          sx={{
            fontSize: 16,
            borderBottom: '1px solid black',
            cursor: 'pointer',
            '&:hover': {
              color: '#1B1BDB',
              borderBottom: '1px solid #FF3FFF',
            },
          }}
        >
          Terms And Conditions
        </Box>
        <Box
          onClick={handleCopyright}
          sx={{
            fontSize: 16,
            borderBottom: '1px solid black',
            cursor: 'pointer',
            '&:hover': {
              color: '#1B1BDB',
              borderBottom: '1px solid #FF3FFF',
            },
          }}
        >
          Copyright
        </Box>
        <Box
          onClick={handleGuidelines}
          sx={{
            fontSize: 16,
            borderBottom: '1px solid black',
            cursor: 'pointer',
            '&:hover': {
              color: '#1B1BDB',
              borderBottom: '1px solid #FF3FFF',
            },
          }}
        >
          Guidelines
        </Box>
      </Box>
    </Box>
  );
};

export default Spark;
