import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Badge, Box, Card, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, useNavigate } from 'react-router-dom';
import AccordionUsage from './accordian-usage';
import Divider from '@mui/material/Divider';
import BasicMenu from './basic-menu';
import logo from '../assest/images/logo.png'
import './Styles/left_Drawer.css'
import Exploredata from '../data/explore-data';
import { exploreScrool } from '../features/addlyric/addLyric';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { url } from '../config/config';
import CancelIcon from '@mui/icons-material/Cancel';
import img from '../assest/images/peacock.jpg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { CiClock2 } from "react-icons/ci";
import ProfileTimeAgo from './profileTimestamps';
import songesss from '../assest/images/premiumIcon.png'

const NotificationDrawer
    = ({ Anchor, value,onNotificationClose }) => {
        const dispatch = useDispatch()
        const userId = '66582a159d847957e91b6923'

        const ClearNotify = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const data = {

                }
                const res = await axios.patch(`${url}/api/Notification/NotificationViewedByUserID`, data, config);
                return res
            } catch (error) {
                return error
            }
        }

        const [data, setData] = useState([])
        const [isPremiumActive, setIsPremiumActive] = useState(false)
        const [payment,setPayment]=useState();

        useEffect(() => {
            const userNotifications = async () => {
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                    const res = await axios.get(`${url}/api/Notification/getNotificationByUserID`, config)
                    console.log(res.data.data, 'notification')
                    setData(res.data.data.data1)
                    const result = await ClearNotify()
                    console.log(result)
                } catch (error) {
                    console.log(error)
                }
            }
            userNotifications()
        }, [value])
        console.log(data)
         useEffect(() => {
        const GetWalletPayment = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
            console.log(res.data, "getWalletBalancesetPayment....")
            setPayment(res.data)
            setIsPremiumActive(res.data.isPremiumActive);
          } catch (error) {
            console.log(error, 'errrors')
          }
        }
        GetWalletPayment()
      }, [])

        // const handleClose=() =>{
        //     handleNotificationcall(false)

        const handleClose = () => {
            onNotificationClose(false)
        }
        // }
        const navigate = useNavigate();
        const handleNavigate =(UserId) => {
            console.log(UserId, 'User------------Id');
            navigate(`/otherProfile/${UserId}`)
            
            }
            const handleSongCollborationNavigate = (val) => {
                console.log(val,'clikedValuessss')
                navigate(`/record/${val._id}`)
              }
        return (

            <Box sx={{ width: '600px' }}>
                <Box sx={{
                    overflowY: 'scroll',

                    '::-webkit-scrollbar': {
                        display: "none"
                    },

                }}>
                    <Box>
                        <Box sx={{ padding: '10px', cursor: 'pointer' }} onClick={handleClose}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
                    </Box>
                </Box>
                <Box>
                    <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px', margin: '20px' }}>
                        <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '32px', fontWeight: 700, padding: '10px', paddingBottom: '20px', paddingTop: '20px' }}>Notifications</Box>
                        {
                            data && data.map((val, ind) => (
                                <>
                                    {
                                        val.users && val.users.map((data, index) => (
                                            val.Type === 0 ?
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px', background: val.isViewed === 1 ? '#d3d3d3' : '', borderRadius: '3px' }}>
                                                    <Box sx={{ display: 'flex', gap: 1, padding: '10px' }}>
                                                        <Box>
                                                            
                                                        <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                    //    isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 15, height: 15, fontSize: 10 }}
                                            alt="Remy Sharp"
                                            src="/broken-image.jpg"
                                        >
                                          <img src={songesss}/>
                                        </Avatar>
                                        //   )
                                    }
                                >
  
                                                            <Avatar src={data.ProfilePhoto} /> </Badge>

                                                        </Box>
                                                        <Box sx={{ display: 'flex', marginTop: '8px', gap: .6 }}>
                                                            <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '15px', fontWeight: 700 }}>{data.Name !== '' ? data.Name : 'User'}</Box>
                                                            <Box>is now following you</Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)', marginTop: '10px', padding: '10px' }}><ProfileTimeAgo timestamp={val.createdAt} /></Box>
                                                </Box> : <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px', background: val.isViewed === 1 ? '#d3d3d3' : '', borderRadius: '3px' }}>
                                                    <Box sx={{ display: 'flex', gap: 1, padding: '15px' }}>
                                                        <Box sx={{cursor:'pointer'}}>
                                                        <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 15, height: 15, fontSize: 10 }}
                                            alt="Remy Sharp"
                                            src="/broken-image.jpg"
                                        >
                                          <img src={songesss}/>
                                        </Avatar>
                                          )
                                    }
                                >
                                                            <Avatar src={data.ProfilePhoto}  onClick={() => handleNavigate(val._id)}/> </Badge>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Box sx={{ display: 'flex', gap: .5, paddingBottom: '5px' }}>
                                                                <Box sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700 }}>{data.Name !== '' ? data.Name : 'User'}</Box>
                                                                <Box>
                                                                    {
                                                                        val.Type && val.Type === 1 ? <Box>loved:</Box> : val.Type && val.Type === 2 ? <Box>commented:</Box> : val.Type && val.Type === 3 ? <Box>Gifted:</Box> : ''
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            {
                                                                val.Type && val.Type === 2 ?
                                                                    <>
                                                                        {
                                                                            val.songcollaborationcomments && val.songcollaborationcomments.map((val, ind) => (
                                                                                <Box sx={{ fontSize: '14px', fontWeight: 400, color: 'rgb(88, 90, 100)' }}>{val.CommentText}</Box>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    : val.Type && val.Type === 3 ? <Box>Gift</Box> : ''
                                                            }
                                                            <Box sx={{ display: 'flex', marginTop: '10px', gap: 1 }}>
                                                                {
                                                                    val.songcollaborations && val.songcollaborations.map((collabData, num) => (
                                                                        <>
                                                                            <Box>
                                                                                <img src={collabData.CoverImageURL} onClick={() => {handleSongCollborationNavigate(data)}}style={{ width: '64px', height: '64px', borderRadius: '4px' }} />
                                                                            </Box>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {
                                                                                    val.karaokes && val.karaokes.map((val, ind) => (
                                                                                        <Box sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700, paddingBottom: '3px' }}>{val.Name}</Box>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    val.songcollaborationsuser && val.songcollaborationsuser.map((val, ind) => (
                                                                                        <Box sx={{ fontSize: '14px', fontWeight: 400, paddingBottom: '5px' }}>{val.Name !== '' ? val.Name : val.Email}</Box>
                                                                                    ))
                                                                                }
                                                                                <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                                                                        <Box><PlayArrowIcon fontSize='small' /></Box>
                                                                                        <Box>{collabData.NoOfPlay}</Box>
                                                                                    </Box>.
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                                                                        <Box><FavoriteIcon fontSize='small' /></Box>
                                                                                        <Box>{collabData.NoOfLikes}</Box>
                                                                                    </Box>.
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                                                                        <Box><CiClock2 color='rgb(200, 202, 207)' fontSize='small' /></Box>
                                                                                        <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)' }}><ProfileTimeAgo timestamp={collabData.createdAt} /></Box>
                                                                                    </Box>

                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    ))
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)', marginTop: '10px', padding: '10px' }}><ProfileTimeAgo timestamp={val.createdAt} /></Box>
                                                </Box>
                                        ))
                                    }
                                </>
                            ))
                        }
                    </Card>
                </Box>
            </Box>
        )
    }

export default NotificationDrawer
