import { Box, Button, Container, Typography,Card,CardMedia, Modal, IconButton, Stack, MenuItem, Menu} from "@mui/material";
import React, { useEffect, useState } from "react";
import PublicIcon from '@mui/icons-material/Public';
import img from './gift-removebg-preview.png';
import img1 from './5988768b5dce60de1522e3f28d69093d.jpg';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../../config/config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModalComponent from "../../songs/componenets/module";

const UserPlaylist = () => {

    const navigate = useNavigate()
    const [isModal,setIsModal] = useState(false)
    const params = useParams()
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleNavigation = (path) => {
    setOpen(false); // Close modal
  };
    const songs = [
        {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'Ranjith',
            Artist : 'Harris jayaraj'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        },
    ]
    const [playlistSongs,setPlaylistSongs] = useState([])
    
    useEffect(() => {
        const GetPlaylist = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaborationPlayList/getSongCollaborationPlayListById/${params.id}`,config)
                console.log(res.data.data,'playlistOfUserrrrr')
                setPlaylistSongs(res.data.data)
            }catch (error){
              console.log(error)
            }
        }   
        GetPlaylist()
    },[])

    
    const modalClose = () => {
        setIsModal(false)
    }

    console.log(playlistSongs[0]?.songcollaborationplaylists,'playlistSongDetsailaaa')
    return(
        <Box>
            <Box sx={{background:'rgb(255, 255, 255)',marginTop:0}}>
            <Container maxWidth='lg'>
                <Box sx={{display:'flex',paddingTop:'30px',justifyContent:'space-between',paddingBottom:'30px'}}>
                    <Box>
                        <Typography sx={{fontSize:'32px',fontWeight:700,color:'rgb(45, 40, 103)'}}>{playlistSongs && playlistSongs[0]?.Name}</Typography>
                        <Box sx={{display:'flex',gap:.5,marginBottom:'10px'}}>
                            <Box><PublicIcon style={{fontSize:'14px',color:'rgb(114, 140, 166)'}}/></Box>
                            <Box>
                                <Typography sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)'}}>Public</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Box sx={{width:'24px',height:'24px',borderRadius:'50%',alignItems:'center'}}>
                                <img src={img}/>
                            </Box>
                            <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)'}}>vijay</Box>
                        </Box>
                        <Box sx={{display:'flex',gap:1.5,marginTop:'20px'}}>
                            <Box sx={{display:'flex',cursor:'pointer',background:'rgb(108, 46, 255)',color:'white',padding:'10px',textAlign:'center',borderRadius:'5px',gap:1,paddingBottom:'5px',paddingRight:'15px',fontSize:'17px',fontWeight:700}}>
                                <Box><PlayArrowIcon/></Box>
                                <Box>Play</Box>
                            </Box>
                            <Box sx={{display:'flex',cursor:'pointer',background:'rgb(45, 40, 103)',color:'white',padding:'10px',textAlign:'center',borderRadius:'5px',gap:1,paddingBottom:'5px',paddingRight:'15px',fontSize:'17px',fontWeight:700}} onClick={() => {setIsModal(true)}}>
                                <Box><ReplyIcon/></Box>
                                <Box>Share</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width:'200px',height:'200px',borderRadius:'2px'}}>
                        <img src={playlistSongs && playlistSongs[0]?.CoverImageURL}/>
                    </Box>
                </Box>
            </Container>
            </Box>
            <Container maxWidth='lg'>
                <Box sx={{marginTop:'20px'}}>
                    <Typography sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{playlistSongs && playlistSongs[0]?.songcollaborations?.length} recordings</Typography>
                </Box>
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',marginTop:'10px'}}>
{
    playlistSongs && playlistSongs[0]?.songcollaborations?.map((data,index)=>(
       <Button  style={{textDecoration:"none"}} onClick={() => {navigate(`/record/${data._id}`)}}>
        <Box sx={{margin:2}}>
      
<Box><Card key={index} sx={{ width: 178,borderRadius:'10px',mb:1}}>
      <CardMedia
        sx={{ height: 178}}
        image={data.CoverImageURL}
      />
    
    </Card></Box>
<Box sx={{textOverflow:"ellipsis"}}>
    {
      data.karaokes &&  data.karaokes.map((val,ind) => (
            <Box
 
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width:178,
              color:"#2D2867",
              fontWeight:800,
              textAlign:'left'
            }}
          >{val.Name} 
          </Box>
        ))
    }
{
   data.users && data.users.map((val,ind) => (
        <>
        <Box  sx={{fontSize:"13px",color:"#2D2867",textAlign:'left',width:178,overflow:'hidden',textOverflow:'ellipsis',textTransform:'capitalize'}} >{val.Name}</Box>
        </>
    ))
}
 <Box sx={{display:'flex'}}>
            <>
            <Box sx={{display:'flex',gap:1,alignItems:'center',alignContent:'center'}}>
                <Box sx={{display:'flex',alignItems:'center'}}>
                    <Box>
                    <PlayArrowIcon style={{color:'rgb(200, 202, 207)',fontSize:'14px',fontWeight:400,marginTop:'5px'}}/> 
                    </Box>
                    <Box sx={{color:'rgb(170, 173, 180)',fontSize:'14px',fontWeight:700}}>
                    {data.NoOfPlay}
                    </Box>
                </Box>
                <Box sx={{display:'flex',alignItems:'center'}}>
                    <Box>
                    <FavoriteIcon style={{color:'rgb(200, 202, 207)',fontSize:'14px',fontWeight:400,marginTop:'5px'}}/> 
                    </Box>
                    <Box sx={{color:'rgb(170, 173, 180)',fontSize:'14px',fontWeight:700}}>
                    {data.NoOfLikes}
                    </Box>
                </Box>
</Box>
            </>
 </Box>
</Box>
</Box></Button>
    ))
}

   </Box>
            </Container>
            <ModalComponent isOpen={isModal} modalLink={`${window.location.href}`} onClose={modalClose} content={'Spread the word'} type={'share'}/>
        </Box>
    )
}

export default UserPlaylist;