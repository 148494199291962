import { Box, Button, Card, colors, Container, Divider, FormControlLabel, IconButton, List, ListItem, Menu, MenuList, Modal, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { LuLoader } from 'react-icons/lu'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FadeLoader, MoonLoader } from 'react-spinners'
import { url } from '../config/config'
import { ArrowBack, Cancel, MoreVert } from '@mui/icons-material'
import image from "../assest/images/upload.jpg"
import { jwtDecode } from 'jwt-decode'
import { IoTrashBin } from 'react-icons/io5'

const Manage = () => {
  
    const navigate = useNavigate();
    const [CoverImage, setCoverImage] = useState(''); 
    const [open, setOpen] = useState(false); // Modal open state
    const [selectedLocation, setSelectedLocation] = useState('');
    const [isopen, setIsOpen] = useState(false);
    const [Location, setLocation] = useState('');
    const coverImage = useMemo(() => {
        if (CoverImage instanceof Blob || CoverImage instanceof File) {
            
          return URL.createObjectURL(CoverImage);
        }
        return CoverImage || ''; // Use the existing URL if it's already a string
      }, [CoverImage]);
      console.log(coverImage,"image")
    const [opens, setOpens] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');  
    const [groupName, setGroupName] = useState('');
    const [hashtagValue, setHashtagValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [description, setDescription] = useState('');
    const [hashtags, setHashtags] = useState('');
    const {state} = useLocation()
    const {id} = useParams();
    console.log(state,"john,,,,,")
    useEffect(()=> {
        console.log(state,'ManageGroupData')
        state&& setDescription(state.Description) 
        state&& setHashtags(state.Hastag) 
        state&& setGroupName(state.ChannelName ) 
        state&& setHashtagValue(state.ChannelName) 
         state&& setCoverImage(state.CoverImage) 
    },[])
  
    const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
    console.log(localStorage.getItem('token'),userTokenData,'datavALUESSSSSSSSSS')
    const sampleLocations = [
        'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix',
        
      ];
      const languages = [
        "English", "Spanish", "French", "German", "Chinese", "Japanese", "Russian", "Hindi", "Arabic", "Portuguese",
      ];
    const Handlesubmit = async () => {
        try {
          const config = {
            headers: { 
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data' 
            }
          };
          const formData = new FormData();
          formData.append('CoverImage', CoverImage); 
          formData.append('ChannelName',groupName);
          formData.append("grouphashtags",hashtagValue)
          formData.append("Description",description);
          formData.append("Hastag",hashtags);
          formData.append("Location",Location);
          formData.append("MemberUserIDs", userTokenData.userId)
          formData.append("language",searchTerm);
          console.log(formData)
          const res = await axios.put(`${url}/api/channel/UpdateChannelByChannelD/${state._id}`, formData, config);
        
          console.log(res,"kesava,,,,,,");
          navigate('/usergroups');
        } catch (error) {
          console.error('Network Error:', error.response ? error.response.data : error.message);
        }
      };
      const HandleDelete = async () => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          };
      
          const res = await axios.delete( `${url}/api/channel/DeleteChannelByChannelD/${state._id}`,
            config
          );
      
          console.log('Delete Response:', res.data);
          navigate('/usergroups');
        } catch (error) {
          console.error('Network Error:', error.response ? error.response.data : error.message);
        }
      };
   

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
      const handleSearch = (e) => {
        setLocation(e.target.value);
      };
    
      const filteredLocations = sampleLocations.filter(location =>
        location.toLowerCase().includes(Location.toLowerCase())
      );
    
      const handleSaves = () => {
        console.log(`Selected location: ${selectedLocation}`);
        handleClose();
      };
      const handleSavess = () => {
        console.log("Selected Language: ", selectedLanguage);
        handleCloses();
      };
      const handleNavigation = () => {
        navigate('/usergroups'); 
      };
      const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
          setCoverImage(e.target.files[0]);
        }
      };
      const handleChange = (event) => {
        setGroupName(event.target.value);
      };
      const handleInputChange = (event) => {
        setHashtagValue(event.target.value);
      };
      const handleChanges = (event) => {
        setDescription(event.target.value);
      };
     const handleChangetags = (event) => {
        setHashtags(event.target.value);
      };
      const handleClickOpen = () => setOpens(true);
      const handleCloses = () => setOpens(false);
    
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredLanguages = languages.filter(lang =>
        lang.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Open the menu
      };
      const handleClosess = () => {
        setAnchorEl(null); // Close the menu
      };
      const handleOpendelete = () => {
        setIsOpen(true);
      };
    
      // Function to handle closing the modal
      const handleClosedelete = () => {
        setIsOpen(false);
      };
    
    
  return (
    <Box sx={{ textAlign: 'center', height: '100vh' }}>
    <Container>
      <Card sx={{ padding: 2, marginTop: '60px' }}>
        <Box
          sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 2, padding: 10, justifyContent: 'space-around',
            paddingTop: '30px', paddingBottom: '40px',}}>
             <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '25px' }}>
            <ArrowBack sx={{ cursor: 'pointer', fontSize: 30 }} onClick={handleNavigation} />      
          </Box> <Box sx={{display:"flex",justifyContent:"end"}}>
            <IconButton   sx={{ 
      "&:hover": {
        color: "primary.main", // Change to your desired hover color
      },}}onClick={handleClick}><MoreVert/></IconButton>
            <Menu 
            anchorEl={anchorEl} 
            open={Boolean(anchorEl)}
            onClose={handleClosess} 
          >  <MenuList
          sx={{
            display: 'flex',
            width: 120,
            justifyContent: 'center',
            alignItems: 'center', 
            height: '20px', 
            cursor: 'pointer',
            color:"#EB4646",
            fontWeight:"bold",
            '&:hover': {
      color: 'white',backgroundColor:"#EB4646" 
    }, 
          }}
          onClick={handleOpendelete} 
        >
          Delete group
        </MenuList></Menu>
        <Modal
        open={isopen}
        onClose={handleClosedelete}
        aria-labelledby="delete-group-modal"
        aria-describedby="delete-group-confirmation"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        > <Box sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EB4646',
            borderRadius: '50%', // Makes the background circular
            padding: 1, // Space around the icon
            width: 40, // Set size of the box
            height: 40,
          }}
        >
          <IoTrashBin size={24} color="white" />
        </Box>
      </Box>
          <Typography sx={{textAlign:'center',fontWeight:"bold"}} id="delete-group-modal" variant="h5" component="h2">
          Wait a minute!
          </Typography>
          <Typography id="delete-group-confirmation" sx={{ mt: 2 }}>
           Are you sure you want to delete this group?
          </Typography>
          <Box sx={{
    mt: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    width:"20",
   
     // Adds spacing between buttons
  }}>
            <Box 
  backgroundColor="#EB4646" 
  borderRadius={2} 
  fontWeight={"bold"}
  color="white" 
  width={300} 
  height={40} 
  display="flex" 
  justifyContent="center" 
  alignItems="center"
  cursor='pointer' 
  textAlign="center"
  onClick={HandleDelete}
  
>
  Delete
</Box>
<Box 
  backgroundColor="#E4E9EE" 
  borderRadius={2} 
  fontWeight={"bold"}
  color="#7591AA" 
  width={300} 
  height={40} 
  display="flex" 
  justifyContent="center" 
  alignItems="center"
  cursor='pointer'  
  textAlign="center"
  onClick={handleClosedelete}
>
  Cancel
</Box>
            
          </Box>
        </Box>
      </Modal>
            
            
            
            
            
            {/* <Button variant='contained' sx={{ height: 30, fontSize: 13 }} onClick={HandleDelete}> Delete</Button> */}
            </Box> 
           
          <Box sx={{ marginBottom: '25px' }}>
            <Box sx={{ fontSize: 40, color: '#120B27', fontWeight: 'bold'  }}>Manage group</Box>
          </Box>
        <Box sx={{ display: 'flex',  gap: 12,  justifyContent: "start",alignItems: "start", }} >
        <Box sx={{ padding: '50px', background: '#EDF5FF', textAlign: "center" ,ml:8}}>
            <Box onClick={Handlesubmit}>
              <img src={CoverImage ? coverImage : image} style={{ width: '200px', height: '240px',mt:10}} alt="image" />
            </Box>
            
            <input
              onChange={handleFileChange}
              type="file"
              name="file"
              id="file"
              className="inputfile"
              style={{ display: 'none' }}
            />
            <label style={{ opacity: 1, background: '#564CF2', color: 'white', padding: '10px', borderRadius: '5px', marginBottom: '10px' }} htmlFor="file">Change Cover Art</label>
          </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                
        
                <Box> <TextField
        fullWidth
        label="Group Name"
        id="fullWidth"
        sx={{ width: 500 }}
        value={groupName}
        onChange={handleChange}
      /> </Box>
            
              <Box> <TextField
        fullWidth
        label="Group Hashtags"
        id="fullWidth"
        sx={{ width: 500 }}
        value={hashtagValue}
        onChange={handleInputChange}
      /></Box>
             
             <TextField
        id="outlined-multiline-static"
        label="Description"
        multiline
        rows={4}
        value={description}
        onChange={handleChanges}
      /> 
              <TextField
        fullWidth
        label="Hashtags"
        id="fullWidth"
        sx={{ width: 500 }}
        value={hashtags}
        onChange={handleChangetags}
      />
              <Box>
      <TextField fullWidth label="Location" id="locationField" value={selectedLocation}
        onClick={handleOpen}placeholder="Click to select a location"/>

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="location-modal-title"
  aria-describedby="location-modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '90vh',
    }}
  >
    <Box sx={{marginLeft:46,cursor:'pointer'}}><Cancel onClick={handleClose}/></Box>
    <Typography id="location-modal-title" variant="h6" component="h2" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '8px' }}>
      Select a Location
    </Typography>
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search location..."
      value={Location}
      onChange={handleSearch}
      sx={{ mb: 2 }}
    />
    <RadioGroup value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
      <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
        {filteredLocations.map((location, index) => (
          <ListItem key={index} disablePadding>
            <FormControlLabel
              value={location}
              control={<Radio />}
              label={location}
              sx={{
                justifyContent: 'flex-start', 
                marginBottom: 1, 
                gap:5,
              }}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
    <Button onClick={handleSaves} variant="contained" color="primary" sx={{ mt: 2 }}>
      Save
    </Button>
  </Box>
</Modal>
    </Box>
    
    <Box>
      <TextField
        fullWidth
        label="Language"
        id="locationField"
        value={selectedLanguage}
        onClick={handleClickOpen}
        placeholder="Click to select a language"
      />

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="location-modal-title"
        aria-describedby="location-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '90vh',
          }}
        >
          <Box sx={{ marginLeft: 46, cursor: 'pointer' }}>
            <IconButton onClick={handleCloses}><Cancel/></IconButton>
            
          </Box>
          <Typography
            id="location-modal-title"
            variant="h6"
            component="h2"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '8px' }}
          >
            Select a Language
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search language..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />
          
          <RadioGroup value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
            <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
              {filteredLanguages.map((lang, index) => (
                <ListItem key={index} disablePadding>
                  <FormControlLabel
                    value={lang}
                    control={<Radio />}
                    label={lang}
                    sx={{
                      justifyContent: 'flex-start',
                      marginBottom: 1,
                      gap: 5,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </RadioGroup>
          <Button onClick={handleSavess} variant="contained" color="primary" sx={{ mt: 2 }}>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
            </Box>
          </Box>
          <Box sx={{display:'flex',justifyContent:'flex-end',gap:45}}>
            <Typography fontWeight={"bold"}>Invite Only</Typography>
            <FormControlLabel  sx={{ display: 'block' }}  control={<Switch 
            color="primary"/> }
            /> </Box>
            <Box sx={{display:'flex',justifyContent:'flex-end',gap:36}}>
            <Typography fontWeight={"bold"}>Automatic Approval</Typography>
            <FormControlLabel  sx={{ display: 'block' }}  control={<Switch 
            color="primary"/> }
            /> </Box>
          <Divider style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}></Divider>
          <Box>
            <Button
              sx={{ width: 300, height: 35, backgroundColor: '#00B818' }}
              variant="contained"
              onClick={Handlesubmit}
            >
              save
            </Button>
          </Box>
        </Box>
      </Card>
    </Container>
  </Box>

  )
}

export default Manage