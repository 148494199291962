
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
const Exploredata =[
    {   
        id:1,
       tittle:'Explore',
       icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
       
       
   },
   {   
    id:1,
   tittle:'Recommended for you',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
   
},
{   
    id:1,
   tittle:'Genres',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
   
},
{   
     id:1,
    tittle:'Moments',
    icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
    
    
},{   
    id:1,
   tittle:'Trending Recordings',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
  
},{   
    id:1,
   tittle:'Whats hot',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
  
},
{   
    id:1,
   tittle:'Groups',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
   
},
{   
    id:1,
   tittle:'Korea',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
   
},
{   
    id:1,
   tittle:'India',
   icon:<ElectricBoltIcon fontSize='large' sx={{color:"red"}}/>,
   
  
   
}
]
export default Exploredata;