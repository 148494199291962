import { Box, Card,Button, Typography, Modal, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import giftImage from './gift-removebg-preview.png'
import CancelIcon from '@mui/icons-material/Cancel';
import { BsCoin } from "react-icons/bs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../../config/config";
import { dark } from "@mui/material/styles/createPalette";
import diment from '../../../assest/images/diamonds_100 (1).png'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
  };

const Module = ({func,data,user,count}) => {

    const params = useParams();
    const [text,setText] = useState('')
    const [choosed,setChoosed] = useState(true)
    const [countGift,setCountGift] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const gifts = [
        {
           image : giftImage,
           coins : 42
        },
        {
            image : giftImage,
            coins : 32
         },
         {
            image : giftImage,
            coins : 16
         },{
            image : giftImage,
            coins : 90
         },{
            image : giftImage,
            coins : 23
         },{
            image : giftImage,
            coins : 78
         },{
            image : giftImage,
            coins : 23
         },
    ]
    console.log(data,'giftDatassssssss')
    const [giftData,setGiftData] = useState('')
    const handleChoose = (ind) => {
        setChoosed(false)
        setGiftData(ind)
    }
    const handleGift = async () => {
        try {
            const giftValues = {
                songCollaborationId : params.id,
                giftId : data[giftData]._id,
                description : text,
                ToUserId : Array(user[0].users[0]._id)
            }
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
              }
            const res = await axios.post(`${url}/api/Gift/send`,giftValues,config);
           console.log(res)
        } catch (error) {
          console.log(error)
        }
        setChoosed(true)
        count(1)
    }

    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      console.log(user)

    return(
        
       <Box>
<Card className="gift-container" sx={{width:'100%',height:screenWidth < 510 ? '100%' :"480px",marginTop:'38px',display:screenWidth > 996 ? 'block' : 'none', overflowY: 'auto',background:'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)'}}>
    <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
        <Box>
            <Box sx={{display:'flex',gap:1}}> 
            {
                choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{color:'rgb(192, 204, 216)'}}/></Box>
            }
                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Send a Gift</Box>
            </Box>
            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>Show appreciation</Box>
        </Box>
        <Box onClick={() => func(true)} sx={{marginTop:'5px'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
    </Box>
    {
        choosed ?  <Box sx={{background:'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)',height:'100%'}}>
        <Box>
            <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',fontWeight:'bold',color:'white'}}>
                <Box>Choose a Sticker:</Box>
                <Box sx={{display:'flex',background:'#7262E7',padding:'5px 10px',borderRadius:'10px',gap:1}}>
                    <Box>0</Box>
                    <Box><img src={diment} size={10} style={{width:20}}/></Box>
                </Box>
            </Box>
        </Box>  
        <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:2,padding:'10px', overflowY: 'auto'}}>
        {
            data && data.map((val,ind) => (
               <Card onClick={() => {handleChoose(ind)}} sx={{width:'120px',marginBottom:'10px',textAlign:'center',alignItems:'center'}}>
                <img style={{width:'76px',height:'76px',marginTop:'10px'}} src={val.GiftImageURL}/>
                <Box sx={{display:'flex',justifyContent:'center',gap:1}}>
                <p style={{color:'rgb(45, 40, 103)'}}>{val?.Gems}</p>
                <Box sx={{marginTop:'17px'}}><BsCoin color="rgb(255, 206, 66)" size={20}/></Box>
                </Box>
               </Card>
            ))
        }
        </Box>
        </Box> : <Box sx={{display:'flex',borderTop:'2px solid #EAE9F0',gap:2}}>
            <Box sx={{background:'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)',height:'100vh',padding:'10px',textAlign:'center'}}>
                <img style={{width:'120px',height:'120px',marginTop:'90px'}} src={data && data[giftData].GiftImageURL}/>
                <Typography sx={{width:'200px',height:'50px',background:'rgb(255, 206, 66)',padding:'10px',borderRadius:'10px',marginTop:'100px'}}>Not enough coins in your wallet.</Typography>
            </Box>
            <Box sx={{marginTop:'70px',textAlign:'center'}}>
                <Box sx={{color:'rgb(114, 140, 166)',paddingBottom:'5px'}}>Send the gift to</Box>
                <Box>
                    <img style={{width:'64px',height:'64px',borderRadius:'50%'}} alt="loading" src={user && user[0].users[0].ProfilePhoto}/>
                    <p style={{marginTop:'0',color:'rgb(45, 40, 103)'}}>{user && user[0].users[0].Email}</p>
                    <input onChange={(e) => {setText(e.target.value)}} placeholder="Say something nice" style={{display:'block',width:'292px',height:'40px',background:'rgb(251, 251, 251)',border:'1px solid rgb(228, 233, 238)',borderRadius:'6px'}}></input>
                    <Button sx={{width:'294px',height:'40px',backgroundColor:'rgb(108, 46, 255)',color:'white',fontWeight:'bold',marginTop:'20px'}} onClick={handleGift}>Send</Button>
                </Box>
            </Box>
        </Box>
    }
</Card>
        <Modal style={{overflowY: 'auto'}}
        open={screenWidth < 996 && screenWidth > 510 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
        <Card className="gift-container" sx={{width:'480px',height:'480px'}}>
            <Box  sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                <Box>
                    <Box sx={{display:'flex',gap:1}}> 
                    {
                        choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{color:'rgb(192, 204, 216)'}}/></Box>

                    }
                        <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Send a Gift</Box>
                    </Box>
                    <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>Show appreciation</Box>
                </Box>
                <Box onClick={() => func(true)} sx={{marginTop:'5px'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
            </Box>
            {
                choosed ?  <Box sx={{background:'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)',height:'100%'}}>
                <Box>
                    <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',fontWeight:'bold',color:'white'}}>
                        <Box>Choose a Sticker:</Box>
                        <Box sx={{display:'flex',background:'#7262E7',padding:'5px 10px',borderRadius:'10px',gap:1}}>
                            <Box>0</Box>
                            <Box><BsCoin color="rgb(255, 206, 66)" size={20}/></Box>
                        </Box>
                    </Box>
                </Box>  
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:1,padding:'10px',overflowY:'scroll'}}>
                {
                    data && data.map((val,ind) => (
                       <Card onClick={() => {handleChoose(ind)}} sx={{width:'130px',height:'150px',marginBottom:'10px',textAlign:'center',alignItems:'center'}}>
                        <img style={{width:'76px',height:'76px',marginTop:'10px'}} src={val.GiftImageURL}/>
                        <Box sx={{display:'flex',justifyContent:'center',gap:1}}>
                        <p style={{color:'rgb(45, 40, 103)'}}>25</p>
                        <Box sx={{marginTop:'17px'}}><BsCoin color="rgb(255, 206, 66)" size={20}/></Box>
                        </Box>
                       </Card>
                    ))
                }
                </Box>
                </Box> : <Box  sx={{display:'flex',borderTop:'2px solid #EAE9F0',gap:2}}>
                    <Box sx={{background:'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)',height:'100vh',padding:'10px',textAlign:'center'}}>
                        <img style={{width:'120px',height:'120px',marginTop:'90px'}} src={data && data[giftData].GiftImageURL}/>
                        <Typography sx={{width:'200px',height:'50px',background:'rgb(255, 206, 66)',padding:'10px',borderRadius:'10px',marginTop:'100px'}}>Not enough coins in your wallet.</Typography>
                    </Box>
                    <Box sx={{marginTop:'70px',textAlign:'center',display:'flex',flexDirection:'column'}}>
                        <Box sx={{color:'rgb(114, 140, 166)',paddingBottom:'5px'}}>Send the gift to</Box>
                        <Box>
                            <img style={{width:'64px',height:'64px',borderRadius:'50%'}} alt="loading" src={user && user[0].users[0].ProfilePhoto}/>
                            <p style={{marginTop:'0',color:'rgb(45, 40, 103)'}}>{user && user[0].users[0].Email}</p>
                            <input onChange={(e) => {setText(e.target.value)}} placeholder="Say something nice" style={{display:'block',width:'100%',height:'40px',background:'rgb(251, 251, 251)',border:'1px solid rgb(228, 233, 238)',borderRadius:'6px'}}></input>
                            <Button sx={{width:'100%',height:'40px',backgroundColor:'rgb(108, 46, 255)',color:'white',fontWeight:'bold',marginTop:'20px'}} onClick={handleGift}>Send</Button>
                        </Box>
                    </Box>
                </Box>
            }
           
        </Card>
        </Box>
        </Modal>
        <Drawer anchor='bottom'
      open={screenWidth < 510 ? true : false} 
      PaperProps={{
        sx : {
          backgroundColor:'white',
        }
      }}
      >
          <Card className="gift-container" sx={{width:'100%',height:'480px'}}>
            <Box  sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                <Box>
                    <Box sx={{display:'flex',gap:1}}> 
                    {
                        choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{color:'rgb(192, 204, 216)'}}/></Box>

                    }
                        <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Send a Gift</Box>
                    </Box>
                    <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>Show appreciation</Box>
                </Box>
                <Box onClick={() => func(true)} sx={{marginTop:'5px'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
            </Box>
            {
                choosed ?  <Box sx={{background:'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)',height:'100%'}}>
                <Box>
                    <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px',fontWeight:'bold',color:'white'}}>
                        <Box>Choose a Sticker:</Box>
                        <Box sx={{display:'flex',background:'#7262E7',padding:'5px 10px',borderRadius:'10px',gap:1}}>
                            <Box>0</Box>
                            <Box><BsCoin color="rgb(255, 206, 66)" size={20}/></Box>
                        </Box>
                    </Box>
                </Box>  
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:1,padding:'10px',overflowY:'scroll'}}>
                {
                    data && data.map((val,ind) => (
                       <Card onClick={() => {handleChoose(ind)}} sx={{width:'130px',height:'150px',marginBottom:'10px',textAlign:'center',alignItems:'center'}}>
                        <img style={{width:'76px',height:'76px',marginTop:'10px'}} src={val.GiftImageURL}/>
                        <Box sx={{display:'flex',justifyContent:'center',gap:1}}>
                        <p style={{color:'rgb(45, 40, 103)'}}>25</p>
                        <Box sx={{marginTop:'17px'}}><BsCoin color="rgb(255, 206, 66)" size={20}/></Box>
                        </Box>
                       </Card>
                    ))
                }
                </Box>
                </Box> : <Box   sx={{display:'flex',borderTop:'2px solid #EAE9F0',gap:screenWidth < 510 ? 0.5 : 2,flexDirection:screenWidth < 510 ? 'column' : 'row',overflowY:'scroll',width:'100%',height:'100%'}}>
                    <Box sx={{background:'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)',height:'100vh',padding:'10px',textAlign:'center',display:'flex',flexDirection:'column',alignContent:'center',justifyContent:'center',alignItems:'center'}}>
                        <img style={{width:'120px',height:'120px',marginTop:screenWidth < 510 ? '10px' : '90px'}} src={data && data[giftData].GiftImageURL}/>
                        <Typography sx={{width:'200px',height:'50px',background:'rgb(255, 206, 66)',padding:'10px',borderRadius:'10px',marginTop:screenWidth < 510 ? '20px' : '100px'}}>Not enough coins in your wallet.</Typography>
                    </Box>
                    <Box sx={{marginTop:'70px',textAlign:'center',display:'flex',flexDirection:'column'}}>
                        <Box sx={{color:'rgb(114, 140, 166)',paddingBottom:'5px'}}>Send the gift to</Box>
                        <Box>
                            <img style={{width:'64px',height:'64px',borderRadius:'50%'}} alt="loading" src={user && user[0].users[0].ProfilePhoto}/>
                            <p style={{marginTop:'0',color:'rgb(45, 40, 103)'}}>{user && user[0].users[0].Email}</p>
                            <Box sx={{display:'flex',width:'100%',justifyContent:'center'}}>
                            <input onChange={(e) => {setText(e.target.value)}} placeholder="Say something nice" style={{width:'50%',height:'40px',background:'rgb(251, 251, 251)',border:'1px solid rgb(228, 233, 238)',borderRadius:'3px'}}></input>
                            </Box>
                            <Box sx={{display:'flex',width:'100%',justifyContent:'center'}}>
                            <Button sx={{width:'50%',height:'40px',backgroundColor:'rgb(108, 46, 255)',color:'white',fontWeight:'bold',marginTop:'20px',marginBottom:screenWidth < 510 ? '70px' : ""}} onClick={handleGift}>Send</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
           
        </Card>
      </Drawer>
       </Box>     
    )
}

export default Module