import React, { useEffect, useState } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Errorpage from './error-page/error-page';
import Explore from './pages/explore/index'
import Topnav from './layouts/top-nav';
import SongBook from './pages/song-book';
import Topartist from './pages/Topartist';
import Apps from './pages/apps';
import About from './pages/about';
import Blog from './pages/blog';
import Styles from './pages/styles';
import Careers from './pages/careers';
import Press from './pages/press';
import Guidelines from './pages/guidelines';
import Smulescience from './pages/smule-science';
import Help from './pages/help';
import Settings from './pages/settings';
import Home from './pages/home-page';
import Recording from './pages/recordings';
import Songs from './pages/songs';
import Group from './pages/gorups';
import Uploads from './pages/uploads';
import Uploadsong from './pages/uploads/compoenets/upload-song';
import Songdetail from './pages/uploads/compoenets/song-detail';
import Songlyrics from './pages/uploads/compoenets/song-lyrics';
import Songstructure from './pages/uploads/compoenets/song-structure';
import UplaodSticker from './pages/upload-sticker';
import Cropimage from './pages/upload-sticker/componenets/crop-image';
import Addinfo from './pages/upload-sticker/componenets/add-info';
import Addimage from './pages/upload-sticker/componenets/add-image';
import UplaodBeat from './pages/upload-beats';
import Beatupload from './pages/upload-beats/componenets/beat-upload';
import BeatDeatil from './pages/upload-beats/componenets/beat-deatil';
import BeatMark from './pages/upload-beats/componenets/beat-mark';
import UserProfile from './pages/user-profile';
import Notification from './pages/notification';
import { Provider } from 'react-redux';
import { store } from './reduxStore/store';
import Songtiming from './pages/uploads/compoenets/song-timing';
import PrivateRoutes from './privateroute/PrivateRoutes ';
import Search from './pages/search';
import Subscribe from './pages/subscribe';
import UserPlaylist from './pages/user-profile/componenets/playlist';
import ExploreView from './pages/recordings/components/listScreen';
import SongDetails from './pages/song-details';
import OtherUserProfile from './pages/othersProfile';
import JoinList from './pages/JoinListscreen';
import SongCollabEdit from './components/songCollabEdit';
import Uploadgift from './pages/upload-gift';
import Usergenres from './pages/user-genres';
import UserCarousel from './pages/usercarousel';
import axios from 'axios';
import { url } from './config/config';
import Seeall from './pages/search/seeall';
import Friend from './pages/referfriend';
import Paragraph from './pages/paragraph';
import Spark from './pages/spark';
import Payment from './components/paymentPortal';
import Topmenu from './pages/topmenu';
import Usergroups from './components/usergroups';
import Creategroups from './components/creategroup';
import Groupinfo from './components/groupinfo';
import Manage from './components/managegroup';
import { element } from 'prop-types';
import PrivacyPolices from './components/privacy_policy';
import Othergroupinfo from './components/othergroupinfo';
import Album from './components/albums';


const router = createBrowserRouter([
  {
    path: "/",
    element: 
      <Topnav/>
    ,
    errorElement: <Errorpage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "explore",
        element: <Explore />,
      },
      {
        path : "subscribe",
        element : <Subscribe/>
      }
      ,
      {
        path: "song",
        element: <SongBook />,
      },
      ,
      {
        path: "topartist",
        element: <Topartist />,
      },
      
      ,
      {
        path: "terms-of-service",
        element: <Apps />,
      },
      ,
      {
        path: "privacy-policy",
        element: <About />,
      },
      ,
      {
        path: "pricing",
        element: <Blog />,
      },
      ,
      {
        path: "gifts-diamonds",
        element: <Styles />,
      },
      ,
      {
        path: "copyright",
        element: <Careers />,
      },
      {
        path : 'playlist/:id',
        element : <UserPlaylist/>
      }     
      ,
      {
        path: "press",
        element: <Press />,
      },
      {
        path : "payment",
        element : <Payment/>
      },
      {
          path : "privacyPolicy",
          element : <PrivacyPolices/>
      },
      {
        path: "guidelines",
        element: <Guidelines />,
      },
      ,
      {
        path: "smulescience",
        element: <Smulescience />,
      },
      ,
      {
        path: "help",
        element: <Help />,
      },
      ,
      {
        path:"categories/:id/:name",
        element:<Paragraph/>,
      },
      ,
      {
        path: "profile",
        element: <UserProfile />,
      },
    {
       path : 'otherProfile/:id',
       element : <OtherUserProfile/>,
    },
    {
       path:"usergroups",
       element:<Usergroups/>,
    },
    {
       path:"creategroups",
       element:<Creategroups/>,
    },
    {
       path:"groupinfo/:id",
       element:<Groupinfo/>,
    },
    {
      path:'managegroup',
      element:<Manage/>
    },
    {
      path:'topmenu',
      element:<Topmenu/>,

    }
      ,
      {
        path: "notification",
        element: <Notification/>,
      },
      ,
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: 'record/:id',
        element: <Recording />,
      },
      {
         path:'albums/:id',
         element:<Album/>
      },
      
      {
        path: "allsong/:id",
        element: <Songs />,
      },
      
      {
        path: "groups",
        element: <Group />,
      },
      {
        path: "search/:value",
        element: <  Search />,
      },
      {
        path:"othergroupinfo/:id",
        element:<Othergroupinfo/>
      },
      {
        path:"searchPagination/:value/:textValue",
        element:<Seeall/>
      },
      {
        path:"friend",
        element:<Friend/>,
      },
      {
        path : 'listen/:id',
        element : <ExploreView/>
      },
     
      {
        path : 'logdetails/:id',
        element : <SongDetails/>
      },
      {
         path : 'joinlist/:id/:ind/:userId',
         element : <JoinList/>
      },
      {
        path : 'songcollabedit/:id',
        element : <SongCollabEdit/>
      },
      {
        path : 'songgiftupload',
        element : <Uploadgift/>
      },
      {
        path : 'geners',
        element : <Usergenres/>
      },
      {
        path : 'carousel',
        element : <UserCarousel/>
      },
      {
        path: "uploadsticker",
        element:<PrivateRoutes  children={<UplaodSticker />}/> ,
        children:[
          {
            path: "addimage",
            element: <Addimage />,
          },
          {
            path: "cropimage",
            element: <Cropimage />,
          },
          {
            path: "addinfo",
            element: <Addinfo />,
          },
        ]
      },
      
      {
        path: "uploads",
        element: <PrivateRoutes  children={   <Uploads />} />
        ,
        children:[
          {
            path: "songupload",
            element: <PrivateRoutes  children={ <Uploadsong />}/>,
          },
          {
            path: "songdetails",
            element:<PrivateRoutes  children={ <Songdetail />}/>,
          },
          {
            path: "songlyrics",
            element:<PrivateRoutes  children={<Songlyrics />}/> ,
          },
          {
            path: "songtiming",
            element:<PrivateRoutes  children={<Songtiming />}/> ,
          },
          {
            path: "songstructure",
            element:<PrivateRoutes  children={ <Songstructure />}/>,
          },
        ]
      },
      {
        path: "uploadbeat",
        element: <PrivateRoutes  children={<UplaodBeat />}/>, 
        children:[
          {
            path: "beats",
            element: <Beatupload />,
          },
          {
            path: "beatdetail",
            element: <BeatDeatil />,
          },
          {
            path: "beatmark",
            element: <BeatMark />,
          },
        ]
      }
    ],
    
  },
  {
    path:"footer",
    element:<Press/>
  }
]);
 
const App = () => {
  // Use any hooks you need here\
  

  return (
    <Provider store={store}>
      <React.StrictMode>
        <GoogleOAuthProvider clientId="382159016115-0ii3bndksa0cf3tl8qu98o9m1o4ng3fq.apps.googleusercontent.com">
            <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </React.StrictMode>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
