import { useCallback, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import group from '../assest/images/groups.jpg'; // Default background image

function EditProfileDragDrop({ onFileUpload, profileImage }) {
    const [dragOver, setDragOver] = useState(false);
    const [displayImage, setDisplayImage] = useState(profileImage || group); // Initialize with profileImage or default image

    // Update display image if profileImage prop changes
    useEffect(() => {
        setDisplayImage(profileImage || group);
    }, [profileImage]);

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event) => {
            event.preventDefault();
            setDragOver(false);
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                const file = event.dataTransfer.files[0];
                handleFileUpload(file);
            }
        },
        []
    );

    const handleChange = useCallback(
        (event) => {
            if (event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                handleFileUpload(file);
            }
        },
        []
    );

    // Handle image upload and preview
    const handleFileUpload = (file) => {
        onFileUpload(file); // Pass uploaded file to parent component
        const reader = new FileReader(); // Use FileReader to preview the uploaded image
        reader.onload = (e) => {
            setDisplayImage(e.target.result); // Update the displayed image
        };
        reader.readAsDataURL(file); // Read file as data URL
    };

    return (
        <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
                padding: 2,
                resize: 'unset',
                textAlign: 'center',
                cursor: 'pointer',
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: dragOver ? '#eee' : '#fafafa',
                backgroundImage: `url(${displayImage})`, // Display uploaded or profile image
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleChange}
            />
            <label htmlFor="raised-button-file">
                <Box display="flex" flexDirection="column" gap={2} alignItems="center">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <CollectionsIcon style={{ fontSize: 60,color:"white" }} />
                    </IconButton>
                    <Box variant="contained" color="white" fontWeight={"bold"}>
                    Change profile photo
                    </Box>
                </Box>
            </label>
        </Box>
    );
}

export default EditProfileDragDrop;
