import { Cancel, Search, SentimentSatisfiedAlt, Start } from '@mui/icons-material';
import { Box, IconButton, Modal, TextField, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paragraph from '../data/helpparagraphs';
import {BsEmojiAngryFill, BsEmojiAstonishedFill, BsEmojiHeartEyesFill, BsEmojiSurprise, BsEmojiSurpriseFill, BsFillEmojiSmileFill} from "react-icons/bs"
import { url } from '../config/config';
import axios from 'axios';
import elpsss from "../assest/images/NewLogo.png"

const Help = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false); 
  const [feedback, setFeedback] = useState(''); 
  const [ishelps,setIshelps]=useState([]);
   const params = useParams()
   console.log(params,"johnnn")

  const groups = [
    ["LATEST FEATURES AND UPDATES", "HOW TO USE SPARKSINGER - INTRODUCTION", "PERKS OF BEING PRE - Find out more about PRE here"],
    ["GIFTING", "PROFILE MANAGEMENT", "HOW TO MANAGE A SPARKSINGER SUBSCRIPTION"],
    ["SAFETY ON THE APP, REPORTING, COPYRIGHT, LEGAL, ETC.", "TECHNICAL ISSUES"],
  ];
  useEffect(() => {
    const detailsHelp = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/help/get-help-heading`,config)
        console.log(res,"helpsssss")
        setIshelps(res.data.data)
        
      } catch (error) {
        console.log(error)
      }
    }
    detailsHelp()
  }, [])
  const handleClicks = async () => {
    try{
     const config={
         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
       }
     const res = await axios.get(`${url}/api/help/get-help-description/${params.id}`,config)
     console.log(res)
    }catch (error){
     console.log(error)
    }
}

  const handleSearch = () => {
    if (searchText.trim() === '') {
      setFilteredGroups([]);
    } else {
      const searchResults = groups
        .map((group) =>
          group.filter((item) => item.toLowerCase().includes(searchText.toLowerCase()))
        )
        .filter((group) => group.length > 0);
      setFilteredGroups(searchResults);
    }
  };
   

  const handleParagraphClick = (id, name) => {
    navigate(`/categories/${id}/${name}` );
  };

  // const handleFeedbackSubmit = () => {
  //   console.log("Feedback Submitted:", feedback);
  //   setModalOpen(false);
  //   navigate('/feedback-success');
  // };

  // const groupsToRender = filteredGroups.length > 0 ? filteredGroups : groups;

  return (
    <Box sx={{backgroundColor:"white"}} >
      <Box sx={{
  backgroundColor: "white",
  display: 'flex',
  justifyContent:"space-around",
  alignItems: "center",
  textAlign: "center",
  padding: 1,
  height:60,
  
  

}}>
  <Box sx={{ width: 200 }}>
    <img src={elpsss} alt="Image" style={{ width: "70%" }} />
  </Box>
  <Box  sx={{color:"black",cursor:"pointer",'&:hover': { color: '#55AEEA' },}}onClick={() => setModalOpen(true)}>
    Submit your feedback
  </Box>
 
</Box>
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "200px", 
    background: "linear-gradient(to right, #AD23C0, #5924E4)", 
    padding: 3,
    
  }}
>

  <Box
    sx={{
      width: "60%",
      marginBottom: 2,
      borderRadius:20,
    }}
  >
    <input
      type="text"
      placeholder="Search..."
      style={{
        width: "40%",
        padding: "10px",
        borderRadius: "20px",
        border: "1px solid #ccc",
        fontSize: "16px",
      }}
    />
  </Box>

  {/* Typography */}
  <Box>
    <Typography
      sx={{ fontWeight: "bold", fontSize: "25px", marginBottom: 1,color:'white' }}
    >
      Alone it's Music,
    </Typography>
    <Typography sx={{ fontWeight: "bold", fontSize: "25px",color:"white" }}>
      Togther it's Magic
    </Typography>
  </Box>
</Box>
<Modal
    open={isModalOpen}
    onClose={() => setModalOpen(false)}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <Box
      sx={{
        width: 400,
        bgcolor: 'white',
        p: 4,
        borderRadius: 2,
        boxShadow: 24,
        textAlign: 'center',
      }}
    >
      <Box sx={{ textAlign: 'right' }}>
        <Cancel onClick={() => setModalOpen(false)} />
      </Box>
      <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
        Submit Your Feedback
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 2 }}>
        <BsEmojiSurpriseFill style={{ fontSize: 40, color: '#FFD700' }} />
        <BsEmojiHeartEyesFill style={{ fontSize: 40, color: '#FFD700' }} />
        <BsFillEmojiSmileFill style={{ fontSize: 60, color: '#FFD700' }} />
        <BsEmojiAstonishedFill style={{ fontSize: 40, color: '#FFD700' }} />
        <BsEmojiAngryFill style={{ fontSize: 40, color: '#FFD700' }} />
      </Box>

      <Typography sx={{ mb: 3, color: '#555' }}>
        We value your thoughts! Please provide your valuable feedback.
      </Typography>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Start />}
          onClick={() => navigate('/feedback')}
          sx={{ mb: 2 }}
        >
          Start Using
        </Button>
        <Typography variant="body2" sx={{ color: '#777' }}>
          Go to the feedback screen
        </Typography>
      </Box>
    </Box>
  </Modal>

<Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          backgroundColor:"white",
          height:600
        }}
      >
        {[
          { id: 1,name:"how-to-use-sparksinger", text: 'How to use SparkSinger' },
          { id: 2,name:"sparkSinger-subscription", text: 'How to manage a SparkSinger subscription' },
          { id: 3,name:"perks-premium", text: 'Perks of being premium' },
          { id: 4,name:"profile-management-sparksinger", text: 'Profile management SparkSinger' },
          { id: 5,name:"safety-on-the-sparkSinger", text: 'Safety on the SparkSinger' },
          { id: 6,name:"technical-issues-sparksinger", text: 'Technical issues SparkSinger ' },
          { id: 7,name:"gifting-on-sparksinger", text: 'Gifting on SparkSinger' },

        ].map((item) => (
          <Box
            key={item.id}
            sx={{
              width: 400,
              height: 100,
              border: '1px solid #6C3FEA',
              backgroundColor: 'white',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#7439ED',
              '&:hover': { backgroundColor: '#6C3FEA', color: 'white', cursor: 'pointer' },
            }}
            onClick={() => handleParagraphClick(item.id,item.name)}
          >
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  


        // ))
      )}
   
 

export default Help;
