import { Avatar, Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import girl from '../../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../config/config';
import TimeAgo from '../recordings/timeStmaps';

const Search = () => {
    const [songcollaboration,setSongCollaboration] = useState([])
    const userTokanValue = localStorage.getItem('token');
const [songs,setSongs] = useState([])
const [user,setUser] = useState([])
const [invites,setInvites] = useState([])
const [groups,setgroups]=useState([])
const[loading,setloading]=useState(true)
const params = useParams();
console.log(params,"johnpetter");

const handleNavigation = (val) => {
    navigate(`/searchPagination/${val}/${params.value}`,);
  };

const navigate=useNavigate()
    useEffect(() => {
        const searchResult = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = userTokanValue !== null  ? await axios.get(`${url}/api/GlobalFilter/getGlobalFilter/${params.value}`,config) 
                : await axios.get(`${url}/api/GlobalFilter/getGlobalOtherFilter/${params.value}`)
                 console.log(res.data.data,'SearchDataValuesssssssss')
                setSongCollaboration(res.data.data[1])
                setSongs(res.data.data[0])
                setUser(res.data.data[2],"inveyyttttt")
                setInvites(res.data.data[3],"inveyyttttt")
                setgroups(res.data.data[4],"inveyyttttt")

            }catch (error) {
                console.log(error,'errorsearchResult')
            }
            finally {
                
                setloading(false);
              }
        }
        searchResult()  
    },[params.value])
    songcollaboration && songcollaboration.map((val) => (
        console.log(val.users.length)
    ))
    console.log(user)
  return (
   <Container sx={{backgroundColor:"#FFFFFF"}}>
    <Box sx={{display:'flex',justifyContent:'center'}}>
             {
              loading?<Box sx={{marginTop:20}}>
                <CircularProgress />
                
            </Box>:null
             }
          </Box>
     <Box pt={4}>
        <Box>
            <Box><Typography fontSize={30}>{`Showing results for "${params.value}"`}</Typography></Box>
        </Box>
        <Box>
           {
            songcollaboration.length>0 ? <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2,cursor:'pointer'}}>
                <Box><Typography>Recordings</Typography></Box>
                <Box onClick={() => handleNavigation(1)}  sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>See All</Typography></Box>
            </Box>:null
           }
            {
                       songcollaboration && songcollaboration.map((data,index) => (
                        index < 6 ?   <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2,cursor:'pointer'}}>
              
                        <><Box key={index} sx={{ display: "flex", gap: 1 }}>
                               <Button  onClick={()=>{
          navigate(`/record/${data._id}`)
        }}><Avatar
                                   alt="Remy Sharp"
                                   src={data.CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' /></Button>
                               <Box sx={{marginTop:'5px'}}>
                                   <Box sx={{ pt: 0 }}><Typography  fontSize={'1em'} fontWeight={700}>{data.karaokes[0].Name}</Typography></Box>
                                   <Box><Typography fontSize={9} style={{width:100,color:'#aaa',fontSize:'1em',fontWeight:400}}>{data.users[0].Email}</Typography></Box>
                                   <Box sx={{ display: "flex", alignItems: "center",gap:.5}}>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><PlayArrowIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfPlay}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><FavoriteIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfLikes}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><ChatBubbleIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfComments}</Box>
                                       </Box>

                                   </Box>
                               </Box>
                           </Box><Box>
                            {
                                data.users.length > 1 ?  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                       <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                       <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                           <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                           <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                       </Box>

                                   </Box> <Box><Typography>+</Typography></Box> <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                           <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                           <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                               <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                               <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                           </Box>

                                       </Box></Box> : <Box onClick={() => {navigate(`/otherProfile/${data.users[0]?._id}`)}} sx={{display:'flex',gap:1}}>
                                    <Box sx={{fontSize:'11px',color:'#636363',marginTop:'10px'}}>Recorded by</Box>
                                    <Box>
                                        <Avatar src={data.users[0].ProfilePhoto}/>
                                    </Box>
                                    <Box sx={{color:'#636363',fontSize:'11px',marginTop:'10px'}}>
                                        {data.users[0].Name !== '' ? data.users[0].Name : data.users[0].Email}
                                    </Box>
                                </Box> 
                            }     
                               </Box><Box><Typography fontSize={13}>Recorded  <TimeAgo timestamp={data.createdAt}/></Typography></Box></>
            </Box> : ''
          
        ))
                }
        </Box>
        <Box>  
            <Box pt={2}>
            {
                songs.length>0?<Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
                <Box><Typography>Songs</Typography></Box>
                <Box onClick={() => {handleNavigation(0)}} sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>see all</Typography></Box>
            </Box>:null
            }
             <Box sx={{display:"flex",flexWrap:'wrap',whiteSpace:'nowrap',padding:'15px',gap:2}}>
                {
                    songs && songs.map((val,ind) => (
                        ind<6 ?
                        <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                        <Box sx={{display:'flex',gap:2}}>
                            <Box>
                            <Avatar
                                   alt="Remy Sharp"
                                   src={val.CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' />
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column',gap:.5,width:'400px'}}>
                                 <Box sx={{color:'#000',fontSize:'12px',fontWeight:700}}>{val.Name}</Box>
                                 {/* <Box sx={{color:'#aaa',fontSize:'12px'}}>Hi-Fi</Box> */}
                                 <Box sx={{display:'flex'}}>
                                    <Box><AccountCircleIcon style={{color:'#aaa'}}/></Box>
                                    <Box sx={{color:'#aaa',fontSize:'12px'}}>{val.users[0]?.Name}</Box>
                                 </Box>
                            </Box>
                        </Box>
                        <Box sx={{marginTop:'10px',cursor:'pointer'}}>
                            <button onClick={() => {navigate(`/allsong/${val._id}`)}} style={{background:'none',border:'1px solid black',padding:'5px 10px',borderRadius:'5px',cursor:'pointer'}}>Lyrics</button>
                        </Box>
                        </Box>:""
                    ))
                }
             </Box>
        </Box>
       </Box>
        <Box pt={2}>

        {
            user.length>0? <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
                <Box><Typography>Users</Typography></Box>
                <Box onClick={() => {handleNavigation(2)}} sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>see all</Typography></Box>
            </Box>:null
        }
            <Box sx={{display:"flex",gap:6}}>
            {
                user && user.map((data,index)=>(
                index <  6 ?
                <Box onClick={() => {navigate(`/otherProfile/${data._id}`)}}  key={index} sx={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"left",padding:'15px',gap:2,cursor:'pointer'}}>
                    <Box><Avatar sx={{height:'100px',width:'100px'}} src={data.ProfilePhoto}/></Box>
                    <Box><Typography fontSize={13} fontWeight={700}>{data.Name}</Typography></Box>
                    
                </Box>  : ''
                ))
            }
            </Box>
        </Box>
        <Box pt={2}>
            {/* <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
                <Box><Typography>Invites</Typography></Box>
                <Box onClick={() => {handleNavigation('invites')}}  sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>see all</Typography></Box>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2}}>
                <Box sx={{display:"flex",gap:1}}>
                    <Box><Avatar
                        alt="Remy Sharp"
                        src={girl}
                        sx={{ width: 60, height: 60 }}
                        variant='square'
/></Box>
                    <Box>
                    <Box sx={{pt:0}}><Typography fontSize={11} fontWeight={700}>(5min) Ye waqt na kho jaaye</Typography></Box>
                        <Box pt={0.5}><Typography fontSize={9}>,ᴴᴰ full ye waqt na kho jaaye, Main tum mein samaa jaaun</Typography></Box>
                        <Box sx={{display:"flex",alignItems:"center",gap:2,paddingTop:0.5}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><PlayArrowIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>1</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><FavoriteIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>23</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><ChatBubbleIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>22</Box>
                            </Box>
                         
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display:"flex",gap:2,alignItems:"center"}}>
                    <Box><Typography fontSize={13}>Created by</Typography></Box>
                    <Box sx={{display:"flex",gap:0.5,alignItems:"center"}}>
                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                    </Box>
                    
                </Box>
                <Box>
                    <Box><Button sx={{backgroundColor:"Highlight",width:50,height:25,fontSize:6,color:"white"}}>Join Now</Button></Box>
                    <Box sx={{display:"flex",alignItems:"center"}}>
                        <Box><Typography><AccessTimeIcon sx={{fontSize:15}}/></Typography></Box>
                        <Box><Typography fontSize={11}>4 days left</Typography></Box>
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2}}>
                {/* <Box sx={{display:"flex",gap:1}}>
                    <Box><Avatar
                        alt="Remy Sharp"
                        src={girl}
                        sx={{ width: 60, height: 60 }}
                        variant='square'
/></Box>
                    <Box>
                    <Box sx={{pt:0}}><Typography fontSize={11} fontWeight={700}>(5min) Ye waqt na kho jaaye</Typography></Box>
                        <Box pt={0.5}><Typography fontSize={9}>,ᴴᴰ full ye waqt na kho jaaye, Main tum mein samaa jaaun</Typography></Box>
                        <Box sx={{display:"flex",alignItems:"center",gap:2,paddingTop:0.5}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><PlayArrowIcon sx={{fontSize:17}}/></Box>
                                <Box sx={{fontSize:8}}>1</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><FavoriteIcon sx={{fontSize:15}}/></Box>
                                <Box sx={{fontSize:8}}>23</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><ChatBubbleIcon sx={{fontSize:15}}/></Box>
                                <Box sx={{fontSize:8}}>22</Box>
                            </Box>
                         
                        </Box>
                    </Box>
                </Box> */}
             <Box>  
            <Box pt={2}>
            {
                invites&& invites.length>0?<Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
                <Box><Typography>invites</Typography></Box>
                <Box onClick={() => {handleNavigation(3)}} sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>See All</Typography></Box>
            </Box>:null
            }
             <Box sx={{display:"flex",flexWrap:'wrap',whiteSpace:'nowrap',padding:'15px',gap:2}}>
                {
                    invites && invites.map((val,ind) => (
                        ind<6 ?
                        <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                        <Box sx={{display:'flex',gap:2}}>
                            <Box>
                            <Avatar
                                   alt="Remy Sharp"
                                   src={val.karaokes[0].CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' />
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column',gap:.5,width:'400px'}}>
                                 <Box sx={{color:'#000',fontSize:'12px',fontWeight:700}}>{val.karaokes[0].Name}</Box>
                                 {/* <Box sx={{color:'#aaa',fontSize:'12px'}}>Hi-Fi</Box> */}
                                 <Box sx={{display:'flex'}}>
                                    {/* <Box><AccountCircleIcon style={{color:'#aaa'}}/></Box> */}
                                    <Box sx={{color:'#aaa',fontSize:'12px'}}>{val.karaokes[0].Name}</Box>
                                 </Box>
                            </Box>
                        </Box>
                        <Box sx={{marginTop:'10px',cursor:'pointer'}}>
                            <button onClick={() => {navigate(`/allsong/${val._id}`)}} style={{background:'none',border:'1px solid black',padding:'5px 10px',borderRadius:'5px',cursor:'pointer'}}>Lyrics</button>
                        </Box>
                        </Box>:""
                    ))
                }
             </Box>
        </Box>
       </Box>
                {/* <Box sx={{display:"flex",gap:2,alignItems:"center"}}>
                    <Box><Typography fontSize={13}>Created by</Typography></Box>
                    <Box sx={{display:"flex",gap:0.5,alignItems:"center"}}>
                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                    </Box>
                    
                </Box> */}
                {/* <Box>
                    <Box><Button sx={{backgroundColor:"Highlight",width:50,height:25,fontSize:6,color:"white"}}>Join Now</Button></Box>
                    <Box sx={{display:"flex",alignItems:"center"}}>
                        <Box><Typography><AccessTimeIcon sx={{fontSize:15}}/></Typography></Box>
                        <Box><Typography fontSize={11}>4 days left</Typography></Box>
                    </Box>
                </Box> */}
            </Box>
           
        </Box>
        <Box pt={2}>
            {
               groups&& groups.length>0?
    <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
        <Box><Typography>Groups</Typography></Box>
        <Box onClick={() => {handleNavigation(4)}}  sx={{cursor:"pointer"}}>
            <Typography color="green" fontWeight={700}>See All</Typography>
        </Box>
    </Box>:null}
    <Box sx={{display:"flex",gap:4,pt:2}}>
        {groups&&groups.map((group, index) => (
            <Box key={index} sx={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"left"}}>
                <Box>
                    <Avatar
                        alt={group.ChannelName}
                        src={group.CoverImage}
                        sx={{ width: 170, height: 170 }}
                        variant='square'
                        onClick={() =>
                            navigate(`/othergroupinfo/${group._id}`, {
                              state: group,
                            })
                          }
                    />
                </Box>
                <Box>
                    <Typography fontSize={13} fontWeight={700}>{group.ChannelName}</Typography>
                </Box>
                <Box sx={{display:"flex"}}>
                    {/* <Box><AccessTimeIcon sx={{fontSize:20}}/></Box> */}
                    <Box>{group.MemberUserIDsCount} members</Box>
                </Box>
            </Box>
        ))}
    </Box>
</Box>
    </Box>
   </Container>
  )
}

export default Search