import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

import BasicMenu from '../../../components/basic-menu3';
const Searchbar = () => {
  return (
   
    <Box sx={{
  display: "flex",
  flexDirection: {
    sm: "column",
    md: "row"
  },
  alignItems: "center",
  justifyContent: "space-around"
}}>
  <Box>
    <FormControl sx={{ m: 1 }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-password"
        type='text'
        placeholder='Search groups by name'
        sx={{
          width: "65ch",
          height: "5ch",
           boxShadow: '0 4px 6px #E5E1F7', // Added shadow
          borderRadius: '5px'
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="search groups"
              edge="end"
              sx={{
                backgroundColor: '#6C2EFF',
                color: "white",
                '&:hover': {
                  backgroundColor: '#4B1CB0', // Darker purple on hover
                }
              }}
            >
              <SearchIcon/> 
            </IconButton>
          </InputAdornment>
        }
        label="Search"
      />
    </FormControl>
  </Box>
  <Box sx={{ display: "flex" }}> 
    <BasicMenu/>
  </Box>
</Box>
  )
}

export default Searchbar