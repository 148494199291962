import { Box, Divider, IconButton, TextField, Typography, Modal, Container, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { FaEdit } from "react-icons/fa";
import { FaShare } from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import BasicTabs from '../../../components/profile-tabs';
import TransitionsModal from '../../../components/model';
import { Cancel, MoreOutlined } from '@mui/icons-material';
import FollowTab from '../../../components/follow-tabs';
import { IoIosContact } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import DragDropFileUpload from '../../../components/drag.drop-file4';
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { CiLink } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { url } from '../../../config/config';
import girl from '../../../assest/images/girl2.jpg';
import img from '../../../assest/images/03d82d29-8fa2-44ac-b34e-353358b62ee9.jpeg';
import FadeLoader from 'react-spinners/FadeLoader';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaExpand } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { AiOutlineFullscreen } from "react-icons/ai";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import gift from './gift-removebg-preview.png'
import ProfileTimeAgo from '../../../components/profileTimestamps';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { BiSolidMessageRounded } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { PiShareFatLight } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import EditProfileDragDrop from '../../../components/editProfile';
import diamond from '../../../assest/images/diamonds_100 (1).png'
import premium from '../../../assest/images/premiumIcon.png'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 5
};
const Header = ({ func }) => {
  const [value, setValue] = React.useState('1');
  const [uploadImage, setUploadImage] = useState('')
  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false)
  const handleChange = (e) => {
    setBio(e.target.value);
  };
  const handleFileUpload = (file) => {
    setUploadImage(file)
    setPreviewImage(URL.createObjectURL(file));
  };
  const [profile, setprofile] = useState([])
  const [mixSong, setmixSong] = useState([])
  // useEffect(()=>{
  //  setTimeout(() => {
  //   const GetProfile=async()=>{
  //     let token =  window.localStorage.getItem('token')
  //     try {
  //       const res = await axios.get(`http://localhost:4000/profile?page=1`);

  //       console.log(res.data)
  //   } catch (error) {
  //       // Handle errors
  //       console.log(error)
  //   }
  //   }
  //   GetProfile()
  //  }, 1000);
  // },[])
  // useEffect(()=>{
  //   setTimeout(() => {
  //    const GetProfile=async()=>{


  //     try {
  //       let token =  window.localStorage.getItem('token')
  //       const decoded = jwtDecode(token);
  //       const res = await axios.get(`${url}/getmixedsongs/${decoded.userId}`);

  //       setmixSong(res.data.data)
  //       console.log(res.data.data)
  //   } catch (error) {  
  //   }
  //   //    
  //    }
  //    GetProfile()
  //   }, 1000);
  //  },[])
  const [userEmail, setUserEmail] = useState([])
  const [userProfileData, setUserProfileData] = useState([])
  const [followingDetails, setFollowingDetails] = useState([])
  const [followerDetails, setFollowerDetails] = useState([])
  const [followingCount, setFollowingCount] = useState(0)
  const [followerCount, setFollowerCount] = useState(0)
 const[getwallet,setGetWallet] =useState()
 const [wallet, setWallet] = useState({});
 const [payment,setPayment]=useState();
 const [isPremiumActive, setIsPremiumActive] = useState(false);
  const [coverImg, setcoverImg] = useState(null)
  const [unFollow, setUnFollow] = useState([])
  const navigate = useNavigate();
  const maxLength = 22;

  useEffect(() => {
    const getFollowingData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Following/getFollowingByUserID`, config)
        console.log(res.data.data)
        setFollowingDetails(res.data.data)
        setFollowingCount(res.data.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    getFollowingData()
  }, [])
  console.log(followingDetails)

  useEffect(() => {
    const getFollowersData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Following/getFollowersByUserID`, config)
        console.log(res.data.data, 'follower')
        setFollowerDetails(res.data.data)
        setFollowerCount(res.data.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    getFollowersData()
  }, [unFollow, followingCount])

  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`, config)
        setUserProfileData(res.data.data)
        setBio(res.data.data[0].Bio)
        setUploadImage(res.data.data[0].ProfilePhoto)
        console.log(res.data.data, "profileeeeeeeeeeeeee")
        const email = await res.data.data[0].Email;
        setUserEmail(email.split('@'))
      } catch (error) {
        console.log(error)
      }
    }
    GetProfileData()
  }, [])

  const [count, setCount] = useState([])
  // console.log(count,"profileddatajsjkjkjkhihiuiug")

  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/songcollaboration/getSongCollaborationCountLoginUserID`, config)
        setCount(res.data.data)
        console.log(res.data, "profiledata.......")
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetProfileData()
  }, [])
  console.log(count)
  useEffect(() => {
    const GetWallet = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Wallet/getWalletBalance`, config)
        console.log(res.data, "getWalletBalance....")
        setWallet(res.data)
       
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWallet()
  }, [])
  useEffect(() => {
    const GetWalletPayment = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "getWalletBalancesetPayment....")
        // setPayment(res.data)
        setIsPremiumActive(res.data.isPremiumActive);
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWalletPayment()
  }, [])
  const [bio, setBio] = useState('')
  const Recording_Model = () => {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Box><Typography fontSize={20} fontWeight={700}>Recordings Count</Typography></Box>
        <Box><Typography fontSize={10}>The recordings count represents the total number of recordings you’ve created on Smule. This count may not reflect the number of recordings deleted by you or other users you’ve recorded with.</Typography></Box>
        <Box><Button variant='contained'>ok</Button></Box>
      </Box>
    )
  }

  const handleUnfollow = async (id, val) => {
    console.log(id, 'followingClick')
    setUnFollow((unFollow) => [...unFollow, id])
    setFollowingCount(followingCount - 1)
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.delete(`${url}/api/Following/deleteFollowing/${val}`, config)
      console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFollow = async (val, id) => {
    console.log(val, id, 'TetsValuesssssss')
    setUnFollow((prevUnFollow) => {
      const index = prevUnFollow.indexOf(val?.userData[0]?._id);
      if (index !== -1) {
        return [
          ...prevUnFollow.slice(0, index),
          ...prevUnFollow.slice(index + 1),
        ];
      }
      return prevUnFollow;
    });
    // setFollowingDetails((prevState) => {
    //   const alreadyExists = prevState.some(item => item?.userData[0]?._id === val?.userData[0]?._id);
    //   console.log(alreadyExists,'dataAleardyyyyy')
    //   if (alreadyExists) {
    //     console.log('alreadyExisting');
    //     setUnFollow((prevUnFollow) => {
    //       const index = prevUnFollow.indexOf(val?.userData[0]?._id);
    //       if (index !== -1) {
    //         return [
    //           ...prevUnFollow.slice(0, index),
    //           ...prevUnFollow.slice(index + 1),
    //         ];
    //       }
    //       return prevUnFollow;
    //     });
    //     return prevState;
    //   } else {
    //     return [...prevState, val];
    //   }
    // });
    setFollowingCount(followingCount + 1)
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const request = {
        Name: '',
        FollowingUserID: id
      }
      const res = await axios.post(`${url}/api/Following/createFollowing`, request, config)
      console.log(res.data, 'followSuccesssMessage')
    } catch (error) {
      console.log(error)
    }
  }
  console.log(unFollow, 'followingggggggggggg')

  const handleNavigate = (UserId) => {

    console.log(UserId, 'User.......Id');
    navigate(`/otherProfile/${UserId}`)

  }
  // console.log('List Data');
  console.log(followingDetails, followerDetails, 'dataValuesssssssssss')
  const Folowing_Model = () => {
    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ padding: '5px' }}><Typography sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }} fontSize={13}>{userProfileData.length !== 0 && userEmail[0]}</Typography></Box>

        </Box>
        <Divider />
        <Box><FollowTab
          count={followingCount}
          countFollower={followerCount}
          values={0}
          tab1={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%', }}>
              {
                followingDetails && followingDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val?.userData[0]?._id)}>
                      <Box><Avatar src={val?.userData && val?.userData[0]?.ProfilePhoto} /></Box>
                      <Box><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val?.userData && val?.userData[0]?.Name !== '' ? val?.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      unFollow.includes(val.userData[0]?._id) ? <Box onClick={() => { handleFollow(val, val?.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px' }} startIcon={<GoPlus size={20} />}>
                        Follow
                      </Button></Box> : <Box onClick={() => { handleUnfollow(val.userData[0]?._id, val.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={<IoMdCheckmark size={14} />}>Following
                      </Button>
                      </Box>
                    }
                  </Box>
                ))
              }
            </Box>
          }
          tab2={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
              {
                followerDetails && followerDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val.userData[0]._id)}>
                      <Box><Avatar src={val.userData && val.userData[0]?.ProfilePhoto} /></Box>
                      <Box><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      val.isFollow ? <Box onClick={() => { handleUnfollow(val.userData[0]._id, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={<IoMdCheckmark size={14} />}>
                        Following
                      </Button></Box> : <Box onClick={() => { handleFollow(val, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px' }} startIcon={<GoPlus size={20} />}>
                        Follow
                      </Button></Box>
                    }

                  </Box>
                ))
              }
            </Box>
          }
        /></Box>
      </Box>
    )
  }
  const Follower_Model = () => {

    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ padding: '5px' }}><Typography sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }} fontSize={13}>{userProfileData.length !== 0 && userEmail[0]}</Typography></Box>

        </Box>
        <Divider />
        <Box><FollowTab
          count={followingCount}
          countFollower={followerCount}
          values={1}
          tab1={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
              {
                followingDetails && followingDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val?.userData[0]?._id)}>
                      <Box><Avatar src={val?.userData && val?.userData[0]?.ProfilePhoto} /></Box>
                      <Box><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val?.userData && val?.userData[0]?.Name !== '' ? val?.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      unFollow.includes(val.userData[0]?._id) ? <Box onClick={() => { handleFollow(val, val?.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px' }} startIcon={<GoPlus size={20} />}>
                        Follow
                      </Button></Box> : <Box onClick={() => { handleUnfollow(val.userData[0]?._id, val.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={<IoMdCheckmark size={14} />}>Following
                      </Button>
                      </Box>
                    }
                  </Box>
                ))
              }
            </Box>
          }
          tab2={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
              {
                followerDetails && followerDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val.userData[0]._id)}>
                      <Box><Avatar src={val.userData && val.userData[0]?.ProfilePhoto} /></Box>
                      <Box><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      val.isFollow ? <Box onClick={() => { handleUnfollow(val.userData[0]._id, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={<IoMdCheckmark size={14} />}>
                        Following
                      </Button></Box> : <Box onClick={() => { handleFollow(val, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px' }} startIcon={<GoPlus size={20} />}>
                        Follow
                      </Button></Box>
                    }

                  </Box>
                ))
              }
            </Box>
          }
        /></Box>
      </Box>
    )
  }
  const Edit_Model = () => {

    console.log(bio)
    const handleClick = async () => {
      const formData = new FormData();
      formData.append('ProfileImage', uploadImage)
      formData.append('Bio', bio)
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.put(`${url}/api/User/updateUser`, formData, config);
        console.log(res)
        window.location.reload()

      } catch (error) {
        // Handle errors
        console.log(error)

      }
    }
    return (

      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            <Box><Typography fontSize={16} fontWeight={"bold"}>Edit profile</Typography></Box>
          </Box>

        </Box>
        <Box sx={{ mt: 2 }}>

        </Box>


        {/* Image preview */}
        <Box sx={{ mt: 2, textAlign: 'center' }} >
          <EditProfileDragDrop onFileUpload={handleFileUpload} profileImage={userProfileData[0]?.ProfilePhoto} />
          {/* {uploadImage ? (
            <img
              src={previewImage}
              alt="Preview"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
          ) : (
            <img
              src={userProfileData[0]?.ProfilePhoto}
              alt="Preview"
              style={{ width: '100px', height: '100px', borderRadius: '10%' }}
            />
          )} */}
        </Box>

        <Box>
          <Box sx={{ border: "5px solid #C0CCD8", p: 1, mt: 2, borderRadius: 2}}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box><Typography fontSize={13} fontWeight={"bold"} color='black'>Description</Typography></Box>
              <Box><Typography fontSize={13}>22 Character left</Typography></Box>
            </Box>
            <Box >
              <input placeholder='Singing song' value={bio}  onChange={handleChange} style={{ width: 250, height: 40, outline: "none", border: "none" }}>

              </input>
            </Box>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center" ,mt:2}}>
          <IconButton><Button sx={{ width: 350 }} variant='contained' onClick={handleClick}>  Save</Button></IconButton>
        </Box>
      </Box>
    )
  }
  const Share_Model = () => {
    return (
      <Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: '5px' }}>
            <Box>
              <Box><Typography fontSize={15} fontWeight={700} color={'rgb(45, 40, 103)'}>Share</Typography></Box>
              <Box><Typography fontSize={11} fontWeight={400} color={'rgb(114, 140, 166)'}>Spread the word</Typography></Box>
            </Box>

          </Box>
          <Divider></Divider>
          <Box sx={{ display: "flex", justifyContent: "space-around", marginBottom: '10px', padding: '10px' }}>
            <Box sx={{ display: "flex", alignItems: "center", background: '#f0f2f4', borderRadius: '5px', marginTop: '15px', marginBottom: '15px', padding: '5px', paddingRight: '15px' }}>
              <Box>
                <IconButton><FacebookIcon style={{ fill: 'rgb(16 149 244)' }} /></IconButton>
              </Box>
              <Box><Typography>Facebook</Typography></Box>
            </Box>
            <a style={{ cursor: 'pointer', textDecoration: 'none' }} href='https://wa.me/?text=http://99.62.153.141:5555/profile'>
              <Box sx={{ display: "flex", alignItems: "center", background: '#f0f2f4', borderRadius: '5px', marginTop: '15px', marginBottom: '15px', padding: '5px', paddingRight: '15px' }}>
                <Box><IconButton><ImWhatsapp color='green' /></IconButton></Box>
                <Box><Typography sx={{ color: 'rgb(45, 40, 103)', textDecoration: 'none' }}>Whats app</Typography></Box></Box>
            </a>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, paddingLeft: 2 }}>
          <Box><Avatar sx={{ backgroundColor: "blue" }}><FaTwitter /></Avatar></Box>
          <Box><Avatar><CiLink /></Avatar></Box>
          <Box><Avatar><MdEmail /></Avatar></Box>
          <Box><Avatar><IoIosMore /></Avatar></Box>
        </Box>
      </Box>
    )
  }
  const [expand, setExpand] = useState(false)
  const handleExpand = () => {
    setExpand(true)

  }
  console.log(followerDetails, followingDetails, 'data fieldssssss')
  const handleClose = () => {
    setOpen(false)
  }
  const [fullScreen, setFullScreen] = useState(false)
  const handleFullscreen = () => {
    setFullScreen(true)
  }

  console.log(userEmail)
  return (
    <>
      {
        userProfileData.length !== 0 ? <Box>
          <Box sx={{ background: 'rgb(255 255 255)' }}>
            <Container maxWidth='md'>
              <Box sx={{ backgroundColor: "#3EB8F9", height: expand ? '' : '200px', borderBottomLeftRadius: 10, backgroundImage: `url(${userProfileData.length !== 0 ? userProfileData[0]?.CoverImage : img})`, borderBottomRightRadius: 10, padding: 2, backgroundSize: expand ? '100% 100%' : 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} onClick={() => { setOpen(true) }}>
              <Box
  sx={{
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    display: 'flex',
    alignItems: 'center', // Align items vertically
    justifyContent: "flex-end", // Adds spacing between avatar and text
  }}
>
  <Avatar
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1, // Adds padding inside Avatar
      width: 35, // Set fixed width (slightly larger to accommodate both)
      height: 35, // Set fixed height (slightly larger to accommodate both)
      fontSize: 10, // Ensures font size inside Avatar matches your requirement
      backgroundColor: '#352D49', // Avatar background color
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontSize: 12, color: "white" }}>
        {wallet?.AvailableBalance}
      </span> {/* Display balance */}
      <img
        src={diamond}
        style={{
          width: 20, // Adjust image size to align better with the text
          height: 20, // Keeps the diamond image in proportion
        }}
        alt="diamond"
      />
    </Box>
  </Avatar>
</Box>
<Box sx={{ marginTop: '80px', position: 'relative' }}>
  {/* User Profile Avatar */}
  {userProfileData && userProfileData.length !== 0 && (
    <Avatar 
      sx={{
        width: '162px',
        height: '162px',
        borderRadius: '50%',
        marginTop: '90px',
        opacity: 1,
        top: expand ? 90 : '',
      }} 
      src={userProfileData[0]?.ProfilePhoto} 
    />
  )}
  
  {/* Premium Avatar */}
  {isPremiumActive ? (
        <Avatar
          sx={{
            position: "relative",
            bottom: '40px',
            left: '16%',
            transform: 'translateX(-50%)',
            width: 40,
            height: 40,
            overflow: 'hidden',
            border: '2px solid white', // Added border for a premium look
          }}
        >
          <img
            src={premium}
            alt="Premium Avatar"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Avatar>
       ) : null} 
</Box>

              </Box>
              <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" }, justifyContent: { md: "space-between", sm: "center", xs: "center" }, textAlign: { sm: "center", xs: "center", md: "left" }, padding: 2, paddingTop: '0' }}>
                <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" }, gap: 2, alignItems: "center", justifyContent: "center" }}>
                  <Box sx={{ display: "flex", mt: 12, flexDirection: "column", justifyContent: "center", gap: 0.4, fontFamily: "sans-serif" }}>
                    <Box><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{userProfileData.length !== 0 && userEmail[0]}</Typography></Box>
                    <Box><Typography fontSize={14} fontWeight={500} color={'rgb(170, 173, 180)'}>{userProfileData.length !== 0 && `@${userEmail[0]}`}</Typography></Box>
                    <Box><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{userProfileData[0]?.Bio}</Typography></Box>
                    <Box sx={{ display: "flex", color: "#2D2867", gap: 1 }}>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{count.RecordingResult > 1 ? `${count.RecordingResult} Recordings` : `${count.RecordingResult} Recording`}</Typography>}
                          children={Recording_Model()}
                        />
                      </Box>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{followingCount > 1 ? `${followingCount} Following` : `${followingCount} Following`}</Typography>}
                          children={Folowing_Model()}
                        />
                      </Box>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{followerCount > 1 ? `${followerCount} Followers` : `${followerCount} Follower`}</Typography>}
                          children={Follower_Model()}
                        />
                      </Box>

                    </Box>
                    <Box><Typography fontSize={15} fontWeight={400} color={'rgb(45, 40, 103)'}>{userProfileData.length !== 0 != '' ? userProfileData[0].UserProfileNote : 'born on 2002'}</Typography></Box>

                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  <Box>   <TransitionsModal
                    tittle={<Box> <Button sx={{ backgroundColor: "#2D2867", color: "white" }} size='small' variant="outlined" startIcon={<FaEdit />}>
                      Edit
                    </Button></Box>}

                    children={Edit_Model()}
                  /></Box>
                  <Box>
                    <Box>

                      <TransitionsModal
                        tittle={<Button sx={{ backgroundColor: "#2D2867", color: "white" }} size='small' variant="outlined" startIcon={<FaShare />}>
                          Share
                        </Button>}
                        children={Share_Model()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: 'rgb(228, 233, 238)', height: 2, borderRadius: '2px' }} />
            </Container>
          </Box>
          <BasicTabs mixSong={mixSong} />

        </Box> : <Box sx={{ background: 'rgb(255 255 255)' }}>
          <Container maxWidth='md'>
            <Skeleton animation='wave' variant='rounded' height={200} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Skeleton sx={{ opacity: 1, position: 'absolute', top: 190, marginLeft: '30px' }} variant='circular' animation='wave' width={162} height={162} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, marginTop: '10px' }}>
                <Skeleton variant='rounded' width={75} height={30} />
                <Skeleton variant='rounded' width={75} height={30} />
              </Box>
            </Box>
            <Skeleton sx={{ marginTop: '60px' }} width={200} />
            <Skeleton sx={{ marginTop: '10px' }} width={200} />
            <Box sx={{ display: 'flex', gap: 2, marginTop: '10px' }}>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
            </Box>
            <Skeleton sx={{ marginTop: '10px' }} />
            <Divider sx={{ marginTop: '40px', marginBottom: '20px', bgcolor: 'rgb(228, 233, 238)', height: 2, borderRadius: '2px' }} />
            <Box sx={{ display: 'flex', gap: 4, paddingBottom: '20px' }}>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
            </Box>
          </Container>

        </Box>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ width: fullScreen ? '100%' : '80%', background: '#000000', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <Box onClick={() => {
                  setOpen(false)
                  setFullScreen(false)
                }} sx={{ cursor: 'pointer' }}>
                  <CloseIcon style={{ color: 'white', fontSize: '30px' }} />
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ cursor: 'pointer' }}>
                    <ZoomInIcon style={{ color: 'white', fontSize: '25px' }} />
                  </Box>
                  <Box sx={{ cursor: 'pointer' }}>
                    <ZoomOutIcon style={{ color: 'white', fontSize: '25px' }} />
                  </Box>
                  {
                    fullScreen ? <Box sx={{ cursor: 'pointer' }} onClick={() => { setFullScreen(false) }}>
                      <CloseFullscreenIcon style={{ color: 'white', fontSize: '20px' }} />
                    </Box> : <Box sx={{ cursor: 'pointer' }} onClick={handleFullscreen}>
                      <AiOutlineFullscreen size={25} color='white' />
                    </Box>
                  }
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: fullScreen ? '90%' : '80%' }}>
                <img src={userProfileData.length !== 0 ? userProfileData[0]?.CoverImage : img} style={{ objectFit: 'contain', padding: '40px' }} />
              </Box>
            </Box>
            {
              fullScreen ? '' : <Box sx={{ width: '20%', height: '100%', background: 'white' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px', paddingBottom: '30px' }}>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box>
                        <img style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={userProfileData[0]?.ProfilePhoto} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: 700 }}>{userProfileData[0]?.Name !== '' ? userProfileData[0]?.Name : userProfileData[0]?.Email}</Box>
                        <Box sx={{ color: 'rgb(201 212 222)', fontSize: '14px' }}>15 d</Box>
                      </Box>
                    </Box>
                    <Box><MoreHorizIcon /></Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box><ThumbUpIcon style={{ color: '#0566ff', fontSize: '20px' }} /></Box>
                      <Box sx={{ color: '#65676b', fontSize: '15px' }}>20</Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box><BiSolidMessageRounded style={{ color: '#606770', fontSize: '20px' }} /></Box>
                      <Box sx={{ color: '#606770', fontSize: '15px' }}>20</Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', padding: '10px 20px', gap: 1, borderTop: '1px solid #ced0d4', borderBottom: '1px solid #ced0d4', justifyContent: 'space-between' }}>
                    <Box><ThumbUpIcon style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><BiSolidMessageRounded style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><FaWhatsapp style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><PiShareFatLight style={{ color: '#606770', fontSize: '20px' }} /></Box>
                  </Box>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Header