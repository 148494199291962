import React, { useState, useEffect } from 'react';
import '../styles/carosel.css';
import More from '../../../assest/images/more.png';
import more2 from '../../../assest/images/LEVEL.png';
import more3 from '../../../assest/images/LEVEL.png';

const images = [More, more2, more3];

const Carosel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically switch to the next slide
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Adjust the duration (3000ms = 3 seconds)
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      <div className="carousel-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`carousel-image ${
              index === currentIndex ? 'active' : ''
            }`}
          />
        ))}
      </div>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carosel;