import { Box, Button, Divider, TextField, Container } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import DragDropFileUpload from '../../../components/drag-drop-file2'
import { useNavigate } from 'react-router-dom'
import SongSelect from '../../../components/auto-complete'
import { AddMeta } from '../../../features/addlyric/addLyric'
import { UseDispatch, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import axios from 'axios'
import { url } from '../../../config/config'
import SongSelect1 from '../../../components/auto-complete-1'
import { Tag } from '@mui/icons-material'
import { object, string } from 'prop-types'
const Songdetail = () => {
  const navigate = useNavigate();
  const [tittle, settittle] = useState(null)
  const [genres, setgenres] = useState(null)
  const [Tags, setTags] = useState(null)
  const [coverImg, setcoverImg] = useState(null)
  const [Artits, setArtits] = useState(null)
  const [isfileuplode, setisfileuplode] = useState(false)
  const dispatch = useDispatch()
  const value = useSelector((state) => state)
  console.log(value.Upload)
  const handleFileUpload = (file) => {
    setcoverImg(file)
    console.log(file)
  };
  //  
  console.log(localStorage.getItem('KaraokeEdit'))
  const [genere, setGenere] = useState([])
  // React.useEffect(() => {

  //   const GetGenere = async () => {

  //     try {
  //       const res = await axios.get(`${url}/api/KaraokeMusicType/getKaraokeMusicType`);

  //       setGenere(res.data.data)
  //       //  console.log(res.data.data)
  //     } catch (error) {

  //     }
  //     //    
  //   }
  //   GetGenere()

  // }, [])

  useEffect(() => {
      setcoverImg(value.Upload.SongMeta ? value.Upload.SongMeta.url : '')
      setArtits(value.Upload.SongMeta ? value.Upload.SongMeta.artist : '')
      settittle(value.Upload.SongMeta ? value.Upload.SongMeta.tittle : '')
      setgenres(value.Upload.SongMeta ? value.Upload.SongMeta.genres : '')
      setTags(value.Upload.SongMeta ? value.Upload.SongMeta.tags : '')
  },[])
  const GenreValue = (e, v) => {
    setgenres(v)
  }
  const TagstValue = (e, v) => {
    setTags(v)
  }
  const url = useMemo(() => {
    if (coverImg instanceof Blob || coverImg instanceof File) {
      return URL.createObjectURL(coverImg);
    }
    return coverImg || ''; // Use the existing URL if it's already a string
  }, [coverImg]);

  console.log(coverImg,Artits,tittle,genres,Tags,'all detailsssssss')
  const HandleSubmit = () => {
    if (tittle && Artits && url && genere && Tags) {
      dispatch(AddMeta(
        {
          url,
          tittle: tittle,
          artist: Artits,
          genres: genres,
          tags: Tags
        }
      ))
      navigate('/uploads/songlyrics')
    }
    else{
      console.log('Fill data....');
      
    }
  }
  return (
    <Box sx={{ textAlign: "center", height: "100vh" }}>
      <Container>
        <Card sx={{ padding: 2, marginTop: '60px' }}>
          <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 2, padding: 10, justifyContent: 'space-around', paddingTop: '30px', paddingBottom: '40px' }}>
            <Box sx={{ marginBottom: '25px' }}>
              <Box sx={{ fontSize: 40, color: "#120B27" }}>Add Song Details</Box>
              <Box sx={{ fontSize: 20, color: "#959696" }}>Adding more details makes your song easier to find
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "center", alignItems: "center" }}>
              {
                coverImg !== null && coverImg !== undefined ? <Box sx={{ padding: '15px', background: '#EDF5FF' }}>
                  <Box><img src={coverImg != null && coverImg === String ? coverImg : url} style={{ width: '240px', height: '240px' }} /></Box>
                  <input onChange={(e) => { setcoverImg(e.target.files[0]) }} type="file" name="file" id="file" className="inputfile" />
                  <label style={{ opacity: 1, right: 90, background: '#564CF2', color: 'white', padding: '10px', borderRadius: '5px', marginBottom: '10px' }} for="file">Change Cover Art</label>
                </Box> :
                  <Box>
                    <Box><DragDropFileUpload onFileUpload={handleFileUpload} /></Box>
                  </Box>
              }
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box ><TextField defaultValue={value.Upload.SongMeta.tittle} onChange={(e) => {
                  settittle(e.target.value)
                }} sx={{ width: 500 }} placeholder='Title' /></Box>
                <Box ><TextField defaultValue={value.Upload.SongMeta.artist} onChange={(e) => {
                  setArtits(e.target.value)
                }} sx={{ width: 500 }} placeholder='Artist' /></Box>

                <Box ><SongSelect onchang={GenreValue} tittle='Genre(s):' /></Box>
                <Box ><SongSelect1 onchang={TagstValue} tittle='Tag(s):' /></Box>

              </Box>
            </Box>
            <Divider style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}></Divider>
            <Box>
              <Box><Button sx={{ width: 300, height: 35, backgroundColor: "#00B818" }} variant='contained' onClick={HandleSubmit} >continue</Button></Box>
            </Box>
          </Box>
        </Card>
      </Container>
    </Box>
  )
}

export default Songdetail