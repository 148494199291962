import React from 'react'
import Header from './componenets/header'
import Carosel from './componenets/carosel-view'
import { Box } from '@mui/material'
const Topartist = () => {
  return (
    <div>
        <Header/>
        <Carosel/>
    </div>
  )
}

export default Topartist