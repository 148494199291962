import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import DragDropFileUpload from '../../../components/drag-drop-file'
import DragDropFileUploads from '../../../components/drag-drop-file5'
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { AddAudio, TextFile } from '../../../features/addlyric/addLyric'
const Uploadsong = () => {
    const navigate = useNavigate();
    
    const [spinner,setspinner]=useState(false)
  const dispatch = useDispatch()
    const handleFileUpload = (file) => {
      
      console.log(file)
      if(file){
        const url = URL.createObjectURL(file);
        console.log(url)
        dispatch(AddAudio({ url, name: file.name, size: file.size }));
          // navigate('/uploads/songlyrics')
          // dispatch(AddAudio(file))
      
      }
       
        
      };
     
      const handleFileChosen = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
         console.log(reader)
        reader.onload = (e) => {
          const content = e.target.result;
          console.log(content)
          dispatch(TextFile(content));
        };
    
        reader.readAsText(file);
      };
      console.log('vijay')
    //   cons
  return (
    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",gap:5,alignItems:"center",textAlign:"center",padding:10}}>
        <Box>
            <Box ><Typography fontSize={40} color='#120B27'>Upload Song</Typography></Box>
            <Box><Typography fontSize={20} color='#A39696'>Make sure your file doesn't include vocals.</Typography></Box>
        </Box>
        <Box><DragDropFileUpload onFileUpload={handleFileUpload} spinner={spinner}/></Box>
        <Box sx={{display:"flex",flexDirection:"column"}}>
        <Box><Button > Add lyrics (optional)</Button></Box> <Box><input   accept="text/plain" type="file" onChange={handleFileChosen} /></Box>
          </Box>
        <Box><Button onClick={()=>{
          navigate('/uploads/songdetails')
        }} variant='contained'>contine</Button></Box>
       
        <Box>
            <Box><Typography fontSize={12} color='#9598B4'>By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.</Typography></Box>
            <Box><Typography fontSize={12} color='#9598B4'>If you have any questions, please refer to our FAQ or contact Smule for more assistance.</Typography></Box>
        </Box>
     
    </Box>
  )
}

export default Uploadsong