import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    <Box sx={{textAlign:"center"}}>
      <Box><Typography fontSize={30} fontWeight={700} color='#110E39'>Groups</Typography></Box>
      <Box><Typography fontSize={23} color='#728CA6'>Find or build your community. Share and interact with them. Search and sort groups to</Typography></Box>
      <Box><Typography fontSize={23} color='#728CA6'>find yours.</Typography></Box>
    </Box>
  )
}

export default Header