import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { FaGift } from 'react-icons/fa'
import image from '../assest/images/NewLogoWhite.png'
import image1 from '../assest/images/peaceemoji.png'
import image2 from "../assest/images/box.png"
import image3 from "../assest/images/NewLogo.png"


const Styles = () => {
  return (
    <Box sx={{ backgroundColor: 'white' }}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      padding: { xs: 2, sm: 3, md: 5 }, // Responsive padding
      background: 'linear-gradient(to right,#FF3FFF, #1B1BDB)',
    }}
  >
    <Box sx={{ width: { xs: '150px', sm: '180px', md: '200px' } }}> 
      <img src={image} alt="logo" style={{ width: '100%' }} />
    </Box>
    <Typography
      sx={{
        fontSize: { xs: '30px', sm: '40px', md: '50px' }, // Responsive font size
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: 'center',
      }}
    >
      Gifts & Diamonds
    </Typography>
    <Avatar sx={{ width: { xs: 100, sm: 120, md: 150 }, height: { xs: 100, sm: 120, md: 150 }, backgroundColor: '#E46FFF' }}>
      <FaGift size={60} />
    </Avatar>
  </Box>

  {/* First Section */}
  <Box
    display="flex"
    flexDirection={{ xs: 'column', md: 'row' }} // Stack vertically on small screens
    alignItems="center"
    justifyContent="center"
    mt={4}
    gap={2}
  >
    <img
      src={image1}
      alt="gift"
      style={{
        marginRight: '16px',
        width: '100%', // Full width on small screens
        maxWidth: '30%', // Limit width on larger screens
      }}
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography sx={{ fontSize: { xs: 18, md: 22 } }}>
        <span style={{ fontWeight: 'bold' }}>What are gifts?</span>
        <br />
        Gifts are a fun and exciting way to express your<br />
        appreciation for your favorite singers or songs.<br />
        Show your love by sending them a gift!<br />
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 22 } }}>
        <span style={{ fontWeight: 'bold' }}>What do gifts do?</span>
        <br />
        Gifting your favorite singers goes beyond just<br />
        liking their performances – it’s a special way to <br />
        show your appreciation! Visit our Explore tab to<br />
        discover recordings that have received the most gifts.<br />
        We’re constantly adding new gifts, so stay tuned!<br />
      </Typography>
    </Box>
  </Box>

  {/* Second Section */}
  <Box
    display="flex"
    flexDirection={{ xs: 'column-reverse', md: 'row' }} // Stack vertically on small screens, reverse order
    alignItems="center"
    justifyContent="center"
    mt={4}
    gap={2}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography sx={{ fontSize: { xs: 18, md: 22 } }}>
        <span style={{ fontWeight: 'bold' }}>What are Diamonds?</span>
        <br />
        Diamonds are a virtual currency that you can buy<br />
        and use to send gifts. Show your appreciation <br />
        by gifting your favorite singers with them!<br />
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 22 } }}>
        <span style={{ fontWeight: 'bold' }}>Can I transfer Diamonds?</span>
        <br />
        Diamonds and any gifts you've received cannot be transferred<br />
        to another user. Please note that Diamonds have no cash value,<br />
        are solely for redeeming gifts, and are non-refundable.<br />
        For more details, refer to sparksinger Terms of Service.<br />
      </Typography>
    </Box>
    <img
      src={image2}
      alt="gift"
      style={{
        width: '100%', // Full width on smaller screens
        maxWidth: '30%', // Limit size on larger screens
      }}
    />
  </Box>
</Box>
  )
}

export default Styles