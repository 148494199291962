import { Avatar, Box, Card, Container, Divider, TextField, Typography,Button, IconButton } from '@mui/material'
import React, { useState,useEffect } from 'react'
import girl from '../assest/images/girl.webp'
import Switch from '@mui/material/Switch'; 
import axios from 'axios';
import { url } from '../config/config';
import { CameraAlt, PhotoCamera } from '@mui/icons-material';
const Settings = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const[userProfileData,setUserProfileData]=useState([])
  const[userameData,setUserNameData]=useState([])
  const [showFields, setShowFields] = useState(false);
  const [userEmail,setUserEmail]=useState()
  const [userPassword, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  console.log(userProfileData,"iyiuyiutvuytcurctrctrc")
  useEffect(() => {
    const GetProfileData = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`,config)
        setUserProfileData(res.data.data)
        console.log(res.data.data,'dataValue')
         const email = await res.data.data[0].Email;
        setUserEmail(email.split('@'))
      }catch (error){
         console.log(error)
      }
    }
    GetProfileData()
   },[])
   
   const handleSubmits = async () => {
    try {
      if (!selectedImage) {
        console.error("No image selected.");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("ProfilePhoto", selectedImage);

      const res = await axios.post(`${url}/api/User/updateUser`, formData, config);
      console.log("Image upload response:", res);

      setSelectedImage(null);
     
    } catch (error) {
      console.error(
        "Network Error:",
        error.response ? error.response.data : error.message
      );
    }
  };
  const handleToggle = () => {
    setShowFields(!showFields);
  };

  return (
    
    <Container maxWidth='xl'>
      <Card sx={{ padding: 2, mt: 3, backgroundColor: "white", color: "black" }}>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    {/* Title Section */}
    <Box sx={{ padding: "10px", color: "black" }}>
      <Typography fontWeight={700} fontSize={20}>Settings</Typography>
    </Box>
    
    <Divider />

    {/* Profile Image Section */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
      <Typography fontWeight={600}>Profile images</Typography>
      <Box sx={{ position: "relative", display: "inline-block", width: 90, height: 90 }}>
        <Avatar sx={{ width: 90, height: 90 }} alt="Profile" src={userProfileData[0]?.ProfilePhoto || ""} />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0,
            transition: "opacity 0.3s",
            borderRadius: "50%",
            cursor: "pointer",
            "&:hover": {
              opacity: 1,
            },
          }}
        >
          <PhotoCamera sx={{ color: "white", fontSize: 36 }} />
        </Box>
      </Box>
    </Box>
    
    <Divider sx={{ backgroundColor: "white" }} />
      
    {/* Blurb Section */}
    <Box sx={{ display: "flex",textAlign:'start', gap: 14 }}>
      <Typography fontWeight={600}>Blurb</Typography>
      <Box sx={{ flexGrow: 6 }}>
  <TextField
    variant="outlined"
    value={userProfileData[0]?.Name}
    InputProps={{
      style: {
        fontSize: 18, // Adjust font size
        fontWeight: 300,
        width: "60%", // Adjust width percentage
        color: 'black',
        backgroundColor: 'white',
        height: '40px', // You can add height if needed
      },
    }}
    fullWidth
  />
</Box>
    </Box>
    <Divider sx={{ backgroundColor: "white" }} />
    <Box sx={{ display: "flex",textAlign:'start', gap: 16 }}>
      <Typography fontWeight={600}>Bio</Typography>
      <Box sx={{ flexGrow: 6 }}>
        <TextField
          variant="outlined"
          value={userProfileData[0]?.Bio}
          InputProps={{
            style: {
              fontSize: 15,
              fontWeight: 300,
              width: "60%",
              color: 'black',
              backgroundColor: 'white',
              height: '40px',
            },
          }}
          fullWidth
        />
      </Box>
    </Box>
    <Divider sx={{ backgroundColor: "white" }} />
    

  </Box>
</Card> 
<Card sx={{ padding: 2, mt: 3, backgroundColor: "white", color: "black", fontWeight: "bold" }}>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    {/* Section Title */}
    <Box sx={{ padding: "10px", color: "black" }}>
      <Typography fontWeight={700} fontSize={20}>
        Login Information
      </Typography>
    </Box>

    <Divider />

    {/* Name Fields */}
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 7 }}>
      <Typography fontWeight="bold">Name</Typography>
      <Box sx={{ display: "flex", gap: 5, flex: 1 }}>
        <TextField
          type="text"
          placeholder="First name"
          fullWidth
          InputProps={{
            style: {
              fontSize: 15,
              fontWeight: 300,
              color: "black",
              backgroundColor: "white",
              height: "40px",
            },
          }}
        />
        <TextField
          type="text"
          placeholder="Last name"
          fullWidth
          InputProps={{
            style: {
              fontSize: 15,
              fontWeight: 300,
              color: "black",
              backgroundColor: "white",
              height: "40px",
            },
          }}
        />
      </Box>
    </Box>

    <Divider />

    {/* Username Field */}
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 3 }}>
      <Typography fontWeight="bold">Username</Typography>
      <TextField
        type="text"
        placeholder="Enter username"
        fullWidth
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 300,
            color: "black",
            backgroundColor: "white",
            height: "40px",
          },
        }}
      />
    </Box>

    <Divider />

    {/* Email Field */}
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 7 }}>
      <Typography fontWeight="bold">Email</Typography>
      <TextField
        type="text"
        placeholder="Enter email"
        fullWidth
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 300,
            color: "black",
            backgroundColor: "white",
            height: "40px",
          },
        }}
      />
    </Box>

    <Divider />

    {/* Password Section */}
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography fontWeight="bold">Password</Typography>
        {!showFields && <Typography>*********</Typography>}
        <Button onClick={handleToggle}>
          {showFields ? "Cancel" : "Change Password"}
        </Button>
      </Box>

      {showFields && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
          {/* Password Fields */}
          <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
            <TextField
              type="password"
              placeholder="Current Password"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 15,
                  fontWeight: 300,
                  color: "black",
                  backgroundColor: "white",
                  height: "40px",
                },
              }}
            />
            <TextField
              type="password"
              placeholder="New Password"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 15,
                  fontWeight: 300,
                  color: "black",
                  backgroundColor: "white",
                  height: "40px",
                },
              }}
            />
            <TextField
              type="password"
              placeholder="Confirm New Password"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 15,
                  fontWeight: 300,
                  color: "black",
                  backgroundColor: "white",
                  height: "40px",
                },
              }}
            />
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="contained" color="primary">
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleToggle}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  </Box>
</Card>
<Card sx={{padding:2,mt:3,backgroundColor:"white"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:1,color:"black"}}>
   <Box sx={{padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}>SparkSinger Emails</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider> 
   </Box>
   <Box sx={{display:"flex",justifyContent:"space-between",color:"black"}}>
    <Box><Typography fontWeight={600}>Receive Product Updates from SparkSinger</Typography></Box>
    <Box><Typography><Switch {...label} defaultChecked /></Typography></Box>
   </Box>
      </Card>
    </Container>
  )
}

export default Settings