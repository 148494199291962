
import singing from "../assest/images/ICON png version-01.png"
import uploadimg from "../assest/images/ICON png version-02.png"
import autorep from "../assest/images/autorap.jpg"
import giftmusic from "../assest/images/ICON png version-02.png"

const UploadDropdown=[
{ 
    id:1,
    tittle:"Upload a song to sparksinger",
    describe:" Include your arrangement of a song in the songbook library.",
    path:"uploads/songupload",
    img:<img src={singing}/>
},
{ 
    id:1,
    tittle:"Upload a sticker to sparksinger",
    describe:" Add your custom sticker to the sparksinger sticker catalog.",
    path:"uploadsticker/addimage",
    img:<img src={uploadimg}/>
},
{ 
    id:1,
    tittle:"Upload a beat to autorap to sparksinger",
    describe:" Showcase your beats to the community and gain recognition.",
    path:"uploadbeat/beats",
    img:<img src={singing}/>
},
{ 
    id:1,
    tittle:"Add gift details",
    describe:" Add your arrangement to а song to the songbook library.",
    path:"songgiftupload",
    img:<img src={giftmusic}/>
},
]

export default UploadDropdown;