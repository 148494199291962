import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    
    <Box sx={{textAlign:"center"}}>
        <Box>
            <Typography sx={{fontSize:"2em",color:"#662867"}}>Explore</Typography>
            <Typography sx={{color:"#728CA6"}}>Sing with Joy, Share Your Passion, Let's Spark Singing!</Typography>
            <Typography sx={{color:"#728CA6"}}>Find new music, performances, groups, and challenges. Join in and sing your favorite songs!</Typography>
            <Typography sx={{color:"#728CA6"}}>sing your favorite songs!</Typography>
        </Box>
    </Box>
  )
}

export default Header