import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment'; // You'll need to install this library: npm install moment

const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const calculateTimeAgo = () => {
            const currentTime = moment();
            const uploadTime = moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            const duration = moment.duration(currentTime.diff(uploadTime));
            
            if (duration.asSeconds() < 5) {
                setTimeAgo('just now');
            } else if (duration.asMinutes() < 1) {
                setTimeAgo(`${Math.floor(duration.asSeconds())} sec${duration.asSeconds() >= 2 ? 's' : ''} ago`);
            } else if (duration.asHours() < 1) {
                setTimeAgo(`${Math.floor(duration.asMinutes())} min${duration.asMinutes() >= 2 ? 's' : ''} ago`);
            } else if (duration.asDays() < 1) {
                setTimeAgo(`${Math.floor(duration.asHours())} hr${duration.asHours() >= 2 ? 's' : ''} ago`);
            } else if (duration.asDays() < 7) {
                setTimeAgo(`${Math.floor(duration.asDays())} day${duration.asDays() >= 2 ? 's' : ''} ago`);
            } else if (duration.asDays() < 30) {
                setTimeAgo(`${Math.floor(duration.asWeeks())} week${duration.asWeeks() >= 2 ? 's' : ''} ago`);
            } else if (duration.asDays() < 365) {
                setTimeAgo(`${Math.floor(duration.asMonths())} mon${duration.asMonths() >= 2 ? 's' : ''} ago`);
            } else {
                setTimeAgo(`${Math.floor(duration.asYears())} year${duration.asYears() >= 2 ? 's' : ''} ago`);
            }
        };

        calculateTimeAgo();

        // Calculate and update time ago every minute
        const interval = setInterval(calculateTimeAgo, 10000);

        return () => clearInterval(interval);
    }, [timestamp]);

    return <span>{timeAgo}</span>;
};

export default TimeAgo;
