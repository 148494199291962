import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

export default function SongSelect({ tittle, onchang }) {
  const countries = ['rock', 'melodies', 'classic', 'mass'];
  const data = useSelector((state) => state);

  const [defaultGenres, setDefaultGenres] = React.useState([]);

  React.useEffect(() => {
    const value = data.Upload?.SongMeta || {};
    if (value.genres && value.genres.length > 0) {
      const defaultValue = countries.filter((country) =>
        value.genres.includes(country)
      );
      setDefaultGenres(defaultValue);
    }
  }, [data]);

  console.log(defaultGenres, 'defaultGenresssssss');

  return (
    <Box sx={{ width: 500 }}>
      <Autocomplete
        multiple
        id="country-select-demo"
        options={countries}
        value={defaultGenres}
        onChange={(event, newValue) => {
          setDefaultGenres(newValue);
          onchang(event, newValue);
        }}
        autoHighlight
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option}
          </Box>
        )}
        renderInput={(params) => (
          // The `label` prop adds the title above the input field.
          <TextField
            {...params}
            label={tittle}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // Disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  );
}
