import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  Typography,
  Avatar,
  Divider,
} from '@mui/material';
import { BiPlus } from 'react-icons/bi';
 import axios from 'axios';
import { url } from '../config/config';
import { ThreeDots } from 'react-loader-spinner';
import { BsPlus, BsThreeDots } from 'react-icons/bs';
import { Settings } from '@mui/icons-material';
 const Usergroups = () => {
  const [groups, setGroups] = useState([]);
  const [infogroup,setInfogroup]= useState([])
  const [activeDropdown, setActiveDropdown] = useState(null); // Tracks active dropdown
  const dropdownRefs = useRef([]);
  const params = useParams()
  console.log(params,"kjjjhjhh")
  const navigate = useNavigate();
  const {state} = useLocation()
  


  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  
  useEffect(() => {
    const GetGroup = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
        const res = await axios.get(`${url}/api/channel/getchannelbyuserid`, config);
        console.log(res.data.data, 'Groupssssssssss......');
        setGroups(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    GetGroup();
  }, []);
 

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          if (activeDropdown === index) setActiveDropdown(null);
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  const handleNavigation = () => {
    navigate('/creategroups');
  };
  const handleMenuClick = (action, groupId) => {
    console.log(groupId,"johnnnn")
    if (action === 'Manage group') {
      
      navigate(`/managegroup`,{
        state:groupId
      });
    } else if (action === 'Copy link') {
      // Logic for copying the link
      const groupLink = `http://localhost:3000/groupinfo/${params}`;
      navigator.clipboard.writeText(groupLink); // Copy the dynamic link
      alert('Link copied to clipboard!');
    }
  };
  return (
    <Box>
      <Container maxWidth="xl">
        <Card
          sx={{
            margin: 2.4,
            height: '800px',
            boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
            overflowY: 'auto',
          }}
        >
          
          <Typography
                  sx={{
                    color: '#323232',
                    textAlign: 'center',
                    mt: 3,
                    fontWeight: 'bold',
                    fontSize: { xs: 18, sm: 22, md: 25 },
                  }}
                >
                  Groups
                </Typography>
                
                <Box
                  onClick={handleNavigation}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 1,
                    cursor: 'pointer',
                    color: '#120B27',
                    fontSize: 20,
                    fontWeight: 900,
                    mr: { xs: 2, sm: 10 },
                    '&:hover': { color: '#673ab7' },
                  }}
                >
                  <BiPlus size={40} /> Create group
                </Box>
                <Typography
  sx={{
    fontSize: { xs: 16, sm: 20 },
    color: '#2D2867',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    ml: { xs: 4, sm: 9 },
    mt: 5,
  }}
>
  Owner
</Typography>
<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {groups &&
    [...groups]
      .filter((data) => data.isAdmin) // Filter admins/owners
      .map((data, index) => (
        <Box key={index} sx={{ borderRadius: '8px', padding: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: { xs: 2, sm: 3 },
              mt: 3,
            }}
          >
            <Avatar
              onClick={() => navigate(`/groupinfo/${data._id}`, { state: data })}
              sx={{
                width: { xs: '120px', sm: '160px' },
                height: '120px',
                borderRadius: '5px',
              }}
              src={data.CoverImage}
            />
            <Box sx={{ fontWeight: 'bold', ml: { xs: 2, sm: 4 }, mt: 1 }}>
              {data.ChannelName}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 1,
                mr: 14,
              }}
            >
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                <Box
                  onClick={() => toggleDropdown(index)}
                  sx={{
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <BsThreeDots size={30} />
                </Box>
                {activeDropdown === index && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      right: 0,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      borderRadius: '5px',
                      mt: 1,
                      zIndex: 1000,
                    }}
                  >
                    <Box
                      onClick={() => handleMenuClick('Manage group', data)}
                      sx={{
                        padding: '10px',
                        width: 140,
                        cursor: 'pointer',
                        borderBottom: '1px solid #eee',
                        color: '#000',
                        '&:hover': {
                          backgroundColor: 'rgb(45, 40, 103)',
                          color: '#fff',
                        },
                      }}
                    >
                      Manage group
                    </Box>
                    <Box
                      onClick={() => handleMenuClick('Copy link')}
                      sx={{
                        padding: '10px',
                        cursor: 'pointer',
                        color: '#000',
                        '&:hover': {
                          backgroundColor: 'rgb(45, 40, 103)',
                          color: '#fff',
                        },
                      }}
                    >
                      Copy link
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
</Box>
<Box><Divider sx={{backgroundColor:'#E4E9EE'}}/></Box>
{/* Other Members Heading */}
<Typography
  sx={{
    fontSize: { xs: 16, sm: 20 },
    color: '#2D2867',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    ml: { xs: 4, sm: 9 },
    mt: 5,
  }}
>
   Members
</Typography>
<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {groups &&
    [...groups]
      .filter((data) => !data.isAdmin) // Filter non-admin members
      .map((data, index) => (
        <Box key={index} sx={{ borderRadius: '8px', padding: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: { xs: 2, sm: 3 },
              mt: 3,
            }}
          >
            <Avatar
              onClick={() => navigate(`/groupinfo/${data._id}`, { state: data })}
              sx={{
                width: { xs: '120px', sm: '160px' },
                height: '120px',
                borderRadius: '5px',
              }}
              src={data.CoverImage}
            />
            <Box sx={{ fontWeight: 'bold', ml: { xs: 2, sm: 4 }, mt: 1 }}>
              {data.ChannelName}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 1,
                mr: 12,
                fontWeight: 'bold',
                fontSize: '16px',
                color: 'gray',
              }}
            >
              Member
            </Box>
          </Box>
        </Box>
      ))}
</Box>

          <Box sx={{ mt: 5 }}>
            <Divider />
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default Usergroups;
