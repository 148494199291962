import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Startpage = () => {
  const navigate = useNavigate()
  
  return (
    <Box>
    <Box sx={{textAlign:"center"}}>
        <Typography sx={{fontSize:'32px'}}>Online-Only Offer: Up to 90% off on Premium</Typography>
        <Typography sx={{fontSize:'20px'}}>Unlimited songs and solos, access on both iOS and android, and no ads.</Typography>
        <Typography sx={{paddingTop:7}}><Button sx={{width:"316px",height:"56px",backgroundColor:"#FD286E"}} variant="contained"onClick={() => navigate("/pricing")}>Get started</Button></Typography>
    </Box>
    </Box>
  )
}

export default Startpage