import { Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, MenuItem, Modal, Radio, RadioGroup, Slider, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LuLoader } from 'react-icons/lu'
import { useDispatch, useSelector } from 'react-redux'
import { FadeLoader, MoonLoader } from 'react-spinners'
import { AddMeta } from '../features/addlyric/addLyric'
import DragDropFileUpload from './drag.drop-file4'
import { ArrowBack, Cancel, Mode, Search } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import SongSelect from './auto-complete'
import SongSelect1 from './auto-complete-1'
import image from "../assest/images/upload.jpg"
import axios from 'axios'
import { url } from '../config/config'
import { jwtDecode } from 'jwt-decode'

const Creategroups = () => {
    const navigate = useNavigate();
    const [CoverImage, setCoverImage] = useState(''); 
    const [open, setOpen] = useState(false); // Modal open state
    const [selectedLocation, setSelectedLocation] = useState('');
    const [Location, setLocation] = useState('');
    const coverImage = CoverImage ? URL.createObjectURL(CoverImage) : '';
    const [opens, setOpens] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');  
    const [groupName, setGroupName] = useState('');
    const [hashtagValue, setHashtagValue] = useState('');
    const [description, setDescription] = useState('');
    const [hashtags, setHashtags] = useState('');
    const {state} = useLocation()
    console.log(state,'location')
  
    const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
    console.log(localStorage.getItem('token'),userTokenData,'datavALUESSSSSSSSSS')
    const sampleLocations = [
        'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix',
        
      ];
      const languages = [
        "English", "Spanish", "French", "German", "Chinese", "Japanese", "Russian", "Hindi", "Arabic", "Portuguese",
      ];
    const Handlesubmit = async () => {
        try {
          const config = {
            headers: { 
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data' 
            }
          };
          const formData = new FormData();
          formData.append('CoverImage', CoverImage); 
          formData.append('ChannelName',groupName);
          formData.append("grouphashtags",hashtagValue)
          formData.append("Description",description);
          formData.append("Hastag",hashtags);
          formData.append("Location",Location);
          formData.append("MemberUserIDs", userTokenData.userId)
          formData.append("language",searchTerm);
          console.log(formData)
          const res = await axios.post(`${url}/api/Channel/CreateChannel`, formData, config);
        
          console.log(res,"kesava,,,,,,");
          navigate('/usergroups');
        } catch (error) {
          console.error('Network Error:', error.response ? error.response.data : error.message);
        }
      };
   

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
      const handleSearch = (e) => {
        setLocation(e.target.value);
      };
    
      const filteredLocations = sampleLocations.filter(location =>
        location.toLowerCase().includes(Location.toLowerCase())
      );
    
      const handleSaves = () => {
        console.log(`Selected location: ${selectedLocation}`);
        handleClose();
      };
      const handleSavess = () => {
        console.log("Selected Language: ", selectedLanguage);
        handleCloses();
      };
      const handleNavigation = () => {
        navigate('/usergroups'); 
      };
      const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
          setCoverImage(e.target.files[0]);
        }
      };
      const handleChange = (event) => {
        setGroupName(event.target.value);
      };
      const handleInputChange = (event) => {
        setHashtagValue(event.target.value);
      };
      const handleChanges = (event) => {
        setDescription(event.target.value);
      };
     const handleChangetags = (event) => {
        setHashtags(event.target.value);
      };
      const handleClickOpen = () => setOpens(true);
      const handleCloses = () => setOpens(false);
    
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredLanguages = languages.filter(lang =>
        lang.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
  return (
    <Box sx={{ textAlign: 'center', height: '100vh' }}>
    <Container>
      <Card sx={{ padding: 2, marginTop: '60px' }}>
        <Box
          sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 2, padding: 10, justifyContent: 'space-around',
            paddingTop: '30px', paddingBottom: '40px',}}>
             <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '25px' }}>
            <ArrowBack sx={{ cursor: 'pointer', fontSize: 30 }} onClick={handleNavigation} />      
          </Box>
          <Box sx={{ marginBottom: '25px' }}>
            <Box sx={{ fontSize: 40, color: '#120B27', fontWeight: 'bold'  }}>Create group</Box>
          </Box>
        <Box sx={{ display: 'flex',  gap: 12,  justifyContent: "start",alignItems: "start", }} >
        <Box sx={{ padding: '50px', background: '#EDF5FF', textAlign: "center" ,ml:8}}>
            <Box onClick={Handlesubmit}>
              <img src={CoverImage ? coverImage : image} style={{ width: '200px', height: '240px',mt:10}} alt="Gift Preview" />
            </Box>
            <input
              onChange={handleFileChange}
              type="file"
              name="file"
              id="file"
              className="inputfile"
              style={{ display: 'none' }}
            />
            <label style={{ opacity: 1, background: '#564CF2', color: 'white', padding: '10px', borderRadius: '5px', marginBottom: '10px' }} htmlFor="file">Change Cover Art</label>
          </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              
                <Box> <TextField
        fullWidth
        label="Group Name"
        id="fullWidth"
        sx={{ width: 500 }}
        value={groupName}
        onChange={handleChange}
      /></Box>
            
              <Box> <TextField
        fullWidth
        label="Group Hashtags"
        id="fullWidth"
        sx={{ width: 500 }}
        value={hashtagValue}
        onChange={handleInputChange}
      /></Box>
             
             <TextField
        id="outlined-multiline-static"
        label="Description"
        multiline
        rows={4}
        value={description}
        onChange={handleChanges}
      /> 
              <TextField
        fullWidth
        label="Hashtags"
        id="fullWidth"
        sx={{ width: 500 }}
        value={hashtags}
        onChange={handleChangetags}
      />
              <Box>
      <TextField fullWidth label="Location" id="locationField" value={selectedLocation}
        onClick={handleOpen}placeholder="Click to select a location"/>

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="location-modal-title"
  aria-describedby="location-modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '90vh',
    }}
  >
    <Box sx={{marginLeft:46,cursor:'pointer'}}><Cancel onClick={handleClose}/></Box>
    <Typography id="location-modal-title" variant="h6" component="h2" sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '8px' }}>
      Select a Location
    </Typography>
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search location..."
      value={Location}
      onChange={handleSearch}
      sx={{ mb: 2 }}
    />
    <RadioGroup value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
      <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
        {filteredLocations.map((location, index) => (
          <ListItem key={index} disablePadding>
            <FormControlLabel
              value={location}
              control={<Radio />}
              label={location}
              sx={{
                justifyContent: 'flex-start', 
                marginBottom: 1, 
                gap:5,
              }}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
    <Button onClick={handleSaves} variant="contained" color="primary" sx={{ mt: 2 }}>
      Save
    </Button>
  </Box>
</Modal>
    </Box>
    
    <Box>
      <TextField
        fullWidth
        label="Language"
        id="locationField"
        value={selectedLanguage}
        onClick={handleClickOpen}
        placeholder="Click to select a language"
      />

      <Modal
        open={opens}
        onClose={handleCloses}
        aria-labelledby="location-modal-title"
        aria-describedby="location-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '90vh',
          }}
        >
          <Box sx={{ marginLeft: 46, cursor: 'pointer' }}>
            <IconButton onClick={handleCloses}><Cancel/></IconButton>
            
          </Box>
          <Typography
            id="location-modal-title"
            variant="h6"
            component="h2"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '8px' }}
          >
            Select a Language
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search language..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />
          <RadioGroup value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
            <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
              {filteredLanguages.map((lang, index) => (
                <ListItem key={index} disablePadding>
                  <FormControlLabel
                    value={lang}
                    control={<Radio />}
                    label={lang}
                    sx={{
                      justifyContent: 'flex-start',
                      marginBottom: 1,
                      gap: 5,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </RadioGroup>
          <Button onClick={handleSavess} variant="contained" color="primary" sx={{ mt: 2 }}>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
            </Box>
          </Box>
          <Divider style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}></Divider>
          <Box>
            <Button
              sx={{ width: 300, height: 35, backgroundColor: '#00B818' }}
              variant="contained"
              onClick={Handlesubmit}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Card>
    </Container>
  </Box>

  )
}

export default Creategroups